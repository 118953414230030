/**
 * Retrieve track page
 * @param value
 * @returns {string}
 */
function getTrackPage (value) {
  switch (value) {
    case 'home':
      return 'home'
    default:
      return ''
  }
}

// ---------------------------------------------

export default {
  getTrackPage
}
