import general from './general'
import home from './home'
import corner from './corner'
import filters from './filters'
import player from './player'
import errors from './errors'
import preview from './preview'
import genre from './genre'
import rubric from './rubric'
import fip from './fip'
import modals from './modals'
import navik from './navik'

export default {
  general,
  home,
  corner,
  filters,
  player,
  errors,
  preview,
  genre,
  rubric,
  fip,
  modals,
  navik
}
