import _get from 'lodash/get'

function onClickSubscribeBouquet (error) {
  return {
    track_page: 'FIP',
    track_zone: 'popin',
    track_type: 'clic',
    track_nom: 'blocage_upsell_cover',
    track_detail: 'clic-blocage_upsell_cover-popin-FIP',
    track_cible: _get(error, 'options.upsellLabel', '') + ' - ' + _get(error, 'options.channel.name', '')
  }
}

export default {
  onClickSubscribeBouquet
}
