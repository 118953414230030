export default {
  label: 'Trier par : ',
  defaultFilterLabel: 'Par défaut',
  alphabeticFilterLabel: 'A - Z',
  channelFilterLabel: 'Chaînes',
  accessibility: {
    defaultFilterAccess: 'Trier par défaut',
    alphabeticFilterAccess: 'Trier par ordre alphabétique',
    channelFilterAccess: 'Trier par chaînes'
  }
}
