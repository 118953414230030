import { defineStore } from 'pinia'
import { useErrorStore } from '../errors/errorStore'

export const useNpvrStore = defineStore('npvrStore', {
  state: () => ({
    user: {}
  }),
  actions: {
    async fetchUser () {
      return await this.$nuxt.app.$api.user.getUser()
        .then((user) => {
          this.user = user
        })
        .catch(error => useErrorStore().setAxiosError(error))
    }
  }
})
