<template>
  <div class="layout-header">
    <div class="first-line padding">
      <div class="replay-breadcrumb">
        <router-link
          v-tealium:click="$stats.menu.onClickHomeReplay()"
          :to=" { name: 'Home' }">
          <h1><span lang="en"> {{ configStore.h1.visible }} </span><span class="sr-only"> {{ configStore.h1.hidden }} </span> </h1>
        </router-link>
        <wptv-breadcrumb
          v-if="displayBreadcrumb"
          v-tealium:click="$stats.menu.onClickOnAriane(route)"
          :items="itemsList"
          :show-first-level="true"
          class="breadcrumb-display" />
      </div>
      <div id="replaySearchEngine" />
    </div>
  </div>
</template>
<script>
import WptvBreadcrumb from '@wptv/components/ui/breadcrumb/Breadcrumb.vue'
import seo from '@wptv/seo'
import { getBreadcrumbItems } from './shared/header.utils'
import { mapStores } from 'pinia'
import { useChannelStore } from '../../../store/channels/channelStore'
import { useGenreStore } from '../../../store/genres/genreStore'
import { useGroupStore } from '../../../store/groups/groupStore'
import { useVideoStore } from '../../../store/videos/videoStore'
import { useConfigStore } from '../../../store/config/configStore'

export default {
  name: 'HeaderLayout',
  components: {
    WptvBreadcrumb
  },
  data () {
    return {
      breadcrumbItems: [],
      itemsList: []
    }
  },
  head () {
    return {
      script: [
        {
          type: 'application/ld+json',
          json: seo.getBreadcrumbList(this.itemsList, `${this.$route.path}`, this.$config.confetti.public.root.wassup)
        }
      ]
    }
  },
  computed: {
    ...mapStores(
      useChannelStore,
      useGenreStore,
      useGroupStore,
      useVideoStore,
      useConfigStore),

    displayBreadcrumb () {
      return this.$route.path !== '/'
    },
    route () {
      return this.$route.path
    }
  },
  created () {
    this.$watch(
      () => this.$route.params,
      () => {
        this.setBreadcrumbItems()
      })
  },
  mounted () {
    this.setBreadcrumbItems()
    // recall search zone
    if (window && window.o_generateSearchZone) {
      window.o_generateSearchZone('replaySearchEngine', 'replaySearchEngine')
    }
  },
  methods: {
    setBreadcrumbItems () {
      if (this.displayBreadcrumb) {
        const { bcLevels, keepLastUrl } = getBreadcrumbItems(
          this.$pinia,
          this.$route,
          [...this.breadcrumbItems],
          this.channelStore.category,
          this.groupStore.group,
          this.videoStore.video)

        if (bcLevels?.length > 0) {
          this.breadcrumbItems = bcLevels
          // passing to the breadcrumb component only truthy elements
          this.itemsList = bcLevels.filter(el => !!el)
          // if not on a FIP removing the last url (not supposed to be clickable)
          if (!keepLastUrl) {
            const tempElem = {
              name: this.itemsList[this.itemsList.length - 1].name,
              linkUrl: ''
            }
            this.itemsList[this.itemsList.length - 1] = tempElem
          }
        }
      } else {
        this.breadcrumbItems = []
        this.itemsList = []
      }
    }
  }
}
</script>

<style lang="scss" scoped>

.layout-header {
  max-width: 90rem;
  margin: auto;
  display: flex;
  flex-direction: column;
  @include mediaQueries('padding', (xsmall: 0 0.9375rem 0, medium: 0 0.75rem 0, large: 0 2rem 0, xlarge: 0 2.5rem 0));

  .first-line {
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    justify-content: space-between;
    line-height: 1.5rem;
    @include mediaQueries('padding', (xsmall: pxToRem(20px) 0 0, medium: pxToRem(30px) 0 0));
    @include mediaQueries('font-size', (xsmall: pxToRem(24px), medium: pxToRem(30px), large: pxToRem(34px)));

    .replay-breadcrumb {
      max-width: calc(100% - 270px);
      align-items: flex-end;
      line-height: normal;
      display: flex;

      h1 {
        margin-bottom: auto;
      }

      .breadcrumb-display {
        max-width: 100%;
        // WARNING the values substracted (110px and 136px) corresponds to the width of the title "Replay"
        @include mediaQueries('max-width', (medium: calc(100% - 110px), xlarge: calc(100% - 136px)));
        @media(max-width: px-to-rem(479)) {
          display: none;
        }
      }
    }

    nav {
      /deep/ .breadcrumb {
        padding-top: 0;
        margin-bottom: 0;
        font-size: 1rem;
      }
    }
  }

  #replaySearchEngine {
    display: flex;
    align-items: center;
    z-index: 99;
    @include mediaQueries('right', (
      xsmall: $global-margin--small,
      medium: $global-margin--medium,
      large: $global-margin--large,
      xlarge: $global-margin--xlarge
    ));
    // the following is to only show 'Rechercher' in the search box to have more place for the breadcumb
      @include mediaQueries('max-width', (xsmall: 160px, medium: none));
    ::v-deep label.o-search-label {
      @include mediaQueries('max-width', (xsmall: 106px, medium: none));
    }
  }
}

</style>
