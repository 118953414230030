import _get from 'lodash/get'
import tvmChannels from './tvm/channels'
import tvmGenres from './tvm/genres'
import tvmGroups from './tvm/groups'
import tvmUsers from './tvm/users'
import tvmVideos from './tvm/videos'
import user from './user'
import externalCertificate from './external/certificate'
import aboutApi from './internal/about'
import { useUserStore } from '../../store/user/userStore'

/**
 * Factory to create api object for specific module
 * @param $config
 * @param $http
 * @param $httpUtils
 * @param $pinia
 * @returns {function(*): *&{$http: *, getApplication: function(): *, $config: *, $httpUtils: *, getMCO: function(): *}}
 */
function apiFactory ($config, $http, $httpUtils, $pinia) {
  // Helper to retrieve MCO from store and fallback
  const getMCO = () => useUserStore($pinia).user.mco || 'OFR'

  const getApplication = () => useUserStore($pinia).user.application || _get($config, 'confetti.public.api.defaultApplication')

  return module => ({ $config, $http, $httpUtils, getMCO, getApplication, ...module })
}

export default ({ $pinia, $config, $http, $httpUtils }, inject) => {
  const api = apiFactory($config, $http, $httpUtils, $pinia)

  inject('api', {
    internal: {
      about: api(aboutApi)
    },
    external: {
      certificate: api(externalCertificate)
    },
    tvm: {
      channels: api(tvmChannels),
      genres: api(tvmGenres),
      groups: api(tvmGroups),
      users: api(tvmUsers),
      videos: api(tvmVideos)
    },
    user: api(user)
  })
}
