import capitalizeFirstLetter from '@wptv/filters/src/filters/capitalizeFirstLetter/capitalizeFirstLetter.filter'
import { useChannelStore } from '../../../../store/channels/channelStore'
import { useGenreStore } from '../../../../store/genres/genreStore'

/**
 * get items list for breadcrumb
 * @param {} route
 * @param {[]} items
 * @param {} category
 * @param {} channel
 * @param {} genre
 * @param {} group
 * @param {} video
 * @returns
 */
export function getBreadcrumbItems ($pinia, route, items = [], category, group, video) {
  // bcLevels => breadcrumb levels
  const bcLevels = items
  // to tell if the last item will be clickable or not
  let keepLastUrl = false
  // when arriving on genre page (cinéma, séries, ...)
  if (route.params.genreId) {
    const currentGenre = useGenreStore($pinia).getGenreById(route.params.genreId)
    bcLevels[0] = {
      name: capitalizeFirstLetter(currentGenre.label),
      linkUrl: `/genres/${route.params.genreId}`
    }
    bcLevels[1] = bcLevels[2] = null
  }
  // when arriving on FIP group page
  if (route.params.groupId) {
    const channelFromGroup = useChannelStore($pinia).getChannelById(group.channelId)
    // when arriving directly put channel for first item
    if (!bcLevels[0]) {
      bcLevels[0] = {
        name: capitalizeFirstLetter(channelFromGroup.name),
        linkUrl: `/channels/${group.channelId}`
      }
    }
    // when arriving from category page
    if (route.query.categoryId && route.query.channelId) {
      bcLevels[1] = {
        name: capitalizeFirstLetter(category.name),
        linkUrl: `/channels/${route.query.channelId}/categories/${route.query.categoryId}`
      }
    }
    bcLevels[2] = {
      name: group.title,
      linkUrl: ''
    }
  // when arriving on a unitary FIP page
  } else if (route.params.videoId) {
    const channelFromVideo = useChannelStore($pinia).getChannelById(video.channelId)
    // when arriving on FIP directly put channel for first item
    if (!bcLevels[0]) {
      bcLevels[0] = {
        name: capitalizeFirstLetter(channelFromVideo.name),
        linkUrl: `/channels/${video.channelId}`
      }
    }
    keepLastUrl = true
  }
  // when arriving on channel page
  if (route.params.channelId) {
    const currentChannel = useChannelStore($pinia).getChannelById(route.params.channelId)
    bcLevels[0] = {
      name: currentChannel.name,
      linkUrl: `/channels/${route.params.channelId}`
    }
    // when arriving on category page
    if (route.params.categoryId) {
      bcLevels[1] = {
        name: capitalizeFirstLetter(category.name),
        linkUrl: `/channels/${route.params.channelId}/categories/${route.params.categoryId}`
      }
    } else {
      bcLevels[1] = null
    }
    bcLevels[2] = null
  }
  return { bcLevels, keepLastUrl }
}
