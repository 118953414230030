import { defineStore } from 'pinia'
import { useErrorStore } from '../errors/errorStore'

export const useGenreStore = defineStore('genre', {
  state: () => ({
    genres: []
  }),
  actions: {
    async fetchGenres () {
      return await this.$nuxt.app.$api.tvm.genres.getGenres()
        .then((genres) => {
          this.genres = genres
        })
        .catch(error => useErrorStore().setAxiosError(error))
    },
    async fetchGenreById (id: String) {
      return await this.$nuxt.app.$api.tvm.genres.getGenre(id)
        .then(genre => genre)
        .catch(error => useErrorStore().setAxiosError(error))
    }
  },
  getters: {
    getGenreById: (state) => {
      return (id: String) => {
        return state.genres.find((genre: any) => genre.id === id) || {}
      }
    }
  }
})
