<template>
  <div id="app">
    <div class="container">
      <div class="left-section">
        <div class="content">
          {{ errorTitle }}
        </div>
        <div class="redir-text">
          {{ redirectMessage }}
        </div>
        <div v-if="!error.redirect">
          <div class="redir-text">
            {{ $t('errors.404.generic.or') }}
          </div>
          <router-link
            :to="{ name: 'Home' }"
            class="redir-link">
            {{ $t('errors.404.generic.link') }}
          </router-link>
        </div>
      </div>
      <div class="right-section show-from-medium">
        <img
          :src="notFoundImage"
          class="logo"
          alt="404">
      </div>
    </div>
    <footer>
      <wptv-footer :used-o-s="userAgentStore.osName" />
    </footer>
  </div>
</template>

<script>
import { WptvFooter } from '@wptv/components/ui/footer'
import { mapStores } from 'pinia'
import { useUserAgentStore } from '../store/useragent/userAgentStore'

export default {
  name: 'AppError',
  components: {
    WptvFooter
  },
  beforeRouteLeave () {
    clearTimeout(this.timeout) // making sure to avoid redundant navigation when user clics to redirect immediately
  },
  layout: 'blank',
  props: {
    error: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      timer: null,
      timerTicks: 5,
      notFoundImage: '/images/error.jpg'
    }
  },
  head () {
    return {
      title: this.$t('errors.404.generic.title')
    }
  },
  computed: {
    ...mapStores(useUserAgentStore),
    errorTitle () {
      return this.error.statusCode === 404 ? this.$t('errors.404.generic.notFound') : this.$t('errors.404.generic.unknown')
    },
    redirectMessage () {
      return this.$t(this.error.redirectMessage || 'errors.404.generic.redirectMessage', { count: this.timerTicks })
    },
    redirectLink () {
      return this.error.redirect || { name: 'Home' }
    }
  },
  mounted () {
    this.timer = setInterval(() => {
      this.timerTicks--

      if (this.timerTicks === 0) {
        clearTimeout(this.timer)
      }
    }, 1000)

    setTimeout(() => {
      this.$router.push(this.redirectLink)
    }, 5000)
  }
}
</script>

<style lang="scss" scoped>
  .container {
    background-color: $white;
    display: flex;
    flex: 1;
    align-items: center;
    padding: 20px;
    justify-content: center;

    .left-section {
      text-align: center;
      @include mediaQueries('width', (xsmall: 100%, medium: 50%));
    }

    .content {
      font-size: 45px;
      margin-bottom: 20px;
      line-height: 1.2;
    }

    .redir-text,
    .redir-link {
      margin-top: 10px;
      font-size: 20px;
    }

    .redir-link {
      cursor: pointer;
      font-weight: bold;
      color: $primary;
    }
  }
</style>
