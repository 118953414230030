import Vue from 'vue'
import truncatellipsis from './truncatellipsis/truncatellipsis.filter'
import capitalizeFirstLetter from '@wptv/filters/src/filters/capitalizeFirstLetter/capitalizeFirstLetter.filter'
import formatDuration from '@wptv/filters/src/filters/formatDuration/formatDuration.filter'

Vue.filter('truncatellipsis', truncatellipsis)
Vue.filter('formatDuration', formatDuration)
Vue.filter('capitalizeFirstLetter', capitalizeFirstLetter)

export {
  capitalizeFirstLetter,
  formatDuration
}
