function onClickPartnerLogoLeft (page, tracknom, trackcible, trackdetail) {
  return {
    track_page: page,
    track_zone: 'banner',
    track_nom: tracknom,
    track_type: 'clic',
    track_cible: trackcible,
    track_detail: trackdetail
  }
}

function onClickWatchLive (page, linkDirectChannel) {
  return {
    track_page: page,
    track_zone: 'banner',
    track_nom: 'regarder_live',
    track_type: 'clic',
    track_cible: linkDirectChannel,
    track_detail: 'clic-regarder_live-banner-rubrique'
  }
}

function onClickBack (page, tracknom, trackcible, trackdetail) {
  return {
    track_page: page,
    track_zone: 'banner',
    track_nom: tracknom,
    track_type: 'clic',
    track_cible: trackcible,
    track_detail: trackdetail
  }
}

export default {
  onClickPartnerLogoLeft,
  onClickWatchLive,
  onClickBack
}
