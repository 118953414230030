function onClickItemHeroZone (page, itemId) {
  return {
    track_page: page,
    track_zone: 'mea',
    track_nom: 'choix_emission',
    track_type: 'clic',
    track_cible: itemId,
    track_detail: 'clic-choix_emission-mea-corner'
  }
}

function onClickToWatchChannel (page, linkDirectChannel) {
  return {
    track_page: page,
    track_zone: 'mea',
    track_nom: 'regarder_live',
    track_type: 'clic',
    track_cible: linkDirectChannel,
    track_detail: 'clic-regarder_live-mea-corner'
  }
}

export default {
  onClickItemHeroZone,
  onClickToWatchChannel
}
