export default {
  authentication: {
    title: 'Demande d\'identification',
    text:
      'Pour continuer vous devez vous identifier. Merci de patienter quelques secondes.',
    redirectButton: 'Rediriger maintenant'
  },
  OutOfEu: {
    title: 'Accès réservé',
    text1:
      'La TV d’Orange sur tous les écrans est disponible depuis l’Union Européenne. La liste des pays ainsi que les restrictions sont disponibles sur',
    text2: 'assistance.orange.fr'
  },
  OutOfEuWithICloudPrivateRelayBlocked: {
    title: 'Accès réservé',
    text: 'La disponibilité des services de la TV d’Orange peut être limitée en fonction de votre position géographique.',
    listItem1: 'Vous pouvez profiter des programmes de la TV d’Orange depuis les pays de l’Union Européenne dont la liste et les restrictions sont disponibles sur',
    listItem2: 'Si vous utilisez un VPN pour accéder à la TV d’Orange, merci de le désactiver pour accéder à l’ensemble des services.',
    listItem3: 'Si vous utilisez un appareil sous iOS à partir de la version 15, iPad OS à partir de la version 15 ou MacOS à partir de la version Monterey et que vous êtes abonné au service iCloud+ : pour pouvoir accéder aux contenus veuillez désactiver la fonction Relais Privé. Pour plus d’information merci de consulter la FAQ à ce sujet sur',
    supportLinkText: 'assistance.orange.fr'
  },
  tvInitError: {
    retry: 'Réessayer',
    close: 'Fermer',
    title: 'Désolé, une erreur technique est survenue.',
    content: 'Vous pouvez réessayer dans quelques instants.'
  },
  suspended: {
    SUSPENDED: {
      title: 'Votre offre TV est suspendue',
      text: 'Vous ne disposez plus des droits nécessaires pour accéder à ce service.'
    },
    SUSPENDED_GUEST: {
      title: 'L’offre TV qui vous a été partagée est suspendue',
      text: 'Vous ne disposez plus des droits nécessaires pour accéder à ce service.'
    },
    HOMECHECKSTATUS_INVALID: {
      title: 'Votre partage de la TV d’Orange est temporairement suspendu',
      text: `Ce service est restreint aux membres du foyer. Pour le vérifier, il est nécessaire que vous l’utilisiez régulièrement en étant connecté à la Livebox de votre domicile en ethernet ou en wifi.<br/><b>Pour réactiver ce partage dès maintenant , connectez-vous simplement au réseau de votre Livebox et rafraichissez cette page.</b><br/>Si vous disposez d'autres offres TV, vous pouvez choisir d'utiliser l'une d'elles en attendant de pouvoir réactiver votre partage. `
    },
    changeAccount: 'Changer de compte',
    allOffers: 'Voir vos offres TV',
    close: 'Fermer'
  },
  notSubscribed: {
    title: `Information`,
    content: `Cette chaîne ne fait pas partie de votre offre.`,
    buttonLabel: `Découvrir ce bouquet`,
    urlSosh: 'https://r.orange.fr/r/Owebtv_replay_shop_sosh',
    url: 'https://r.orange.fr/r/Owebtv_replay_shop'
  },
  nomadismNotAllowed: {
    title: `Accès limité`,
    content: `Cette vidéo ne peut être visualisée qu'à domicile. Veuillez connecter votre ordinateur à votre Livebox.`
  }
}
