import { defineStore } from 'pinia'

export const useConfigStore = defineStore('config', {
  state: () => ({
  isShowModal: false,
  modalsToOpen: [],
  h1: { visible: null, hidden: null }
  }),
  actions: {
    addModalToOpen (modalToOpen) {
      this.modalsToOpen.push(modalToOpen)
    },
    showModal () {
      this.isShowModal = true
    },
    closeModal () {
      this.isShowModal = false
      this.modalsToOpen.shift()
    },
    clearModalsToOpen () {
      this.isShowModal = false
      this.modalsToOpen = []
    },
    setH1 (h1) {
      this.h1 = h1
    }
  },
  getters: {
    getFirstModalToOpen: (state) => state.modalsToOpen.length !== 0 ? state.modalsToOpen[0] : null
  }
})
