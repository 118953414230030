<template>
  <wptv-carousel
    :responsive-rules="responsiveRules"
    :show-arrows="false"
    :with-margins="false"
    :show-scroll-bar="false"
    class="carousel-menu ob1-menu-page"
    theme="light">
    <div class="nav o-nav-light">
      <wptv-carousel-item
        :has-responsive-rules="false"
        :aria-label="$t('general.menu.home')"
        :class="['nav-item', { 'active': currentItemSelected === '/' }]">
        <router-link
          v-tealium:click="$stats.menu.onClickHome()"
          :to="{ name: 'Home' }"
          class="nav-link"
          data-wptv="menu-item-home">
          {{ $t('general.menu.home') }}
        </router-link>
      </wptv-carousel-item>
      <wptv-carousel-item
        v-for="item in menu"
        :key="item.id"
        :has-responsive-rules="false"
        :aria-label="item.label"
        :class="['nav-item', { 'active': currentItemSelected === '/genres/' + item.id }]">
        <router-link
          v-tealium:click="$stats.menu.onClickGenre(item.label, item.id)"
          :to="{ name: 'Genre', params: { genreId: item.id } }"
          :class="['nav-item', 'nav-link', { 'active': currentItemSelected }]"
          data-wptv="menu-item-genre">
          {{ item.label | capitalizeFirstLetter }}
        </router-link>
      </wptv-carousel-item>
    </div>
  </wptv-carousel>
</template>

<script>
import { WptvCarousel, WptvCarouselItem } from '@wptv/components/ui/carousel'

export default {
  name: 'MobileMenu',
  components: {
    WptvCarousel,
    WptvCarouselItem
  },
  props: {
    menu: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      responsiveRules: { xxsmall: 1, xsmall: 2, small: 3, medium: 3, large: 3, xlarge: 4, xxlarge: 4 },
      currentItemSelected: null,
      sticked: false
    }
  },
  watch: {
    '$route' () {
      this.currentItemSelected = this.$router.currentRoute.path
    }
  }
}
</script>

<style lang="scss" scoped>
.carousel-menu {
  padding: 0;
  margin: 0;
  height: 100% !important;

  /deep/ .scroll-container .items-container {
    margin: 0;
    padding-left: 0.75rem;
  }

  .scroll-container {
    .items-container {
      .o-nav-light {
        padding-left: 0;
      }
    }
  }
}
</style>
