<template>
  <nav ref="nav" class="ob1-menu-page">
    <wptv-menu ref="leftSide">
      <wptv-menu-item
        :selected="currentItemSelected === '/'"
        @click.native="resetLabel">
        <router-link
          v-tealium:click="$stats.menu.onClickHome()"
          :aria-label="$t('general.menu.home')"
          :to="{ name: 'Home' }"
          class="nav-link"
          data-wptv="menu-item-home">
          {{ $t('general.menu.home') }}
        </router-link>
      </wptv-menu-item>
      <wptv-menu-item
        v-for="item in visibleMenu"
        :key="item.id"
        :aria-label="item.label"
        :selected="currentItemSelected === '/genres/' + item.id"
        @click.native="resetLabel">
        <router-link
          v-tealium:click="$stats.menu.onClickGenre(item.label, item.id)"
          :to="{name: 'Genre', params: {genreId: item.id}}"
          class="nav-link"
          data-wptv="menu-item-genre">
          {{ item.label | capitalizeFirstLetter }}
        </router-link>
      </wptv-menu-item>
    </wptv-menu>
    <div class="right-side">
      <wptv-dropdown
        ref="dropMenu"
        :label="$t('general.menu.default_menu_lateral')"
        :aria-label="ariaSideMenuLabel"
        :default-value="defaultFilter"
        :items="hiddenMenu"
        class="nav-dropdown-menu"
        align="right"
        data-wptv="menu-item-dropdown"
        @input="selectFromDropDown" />
      <div :class="[{ 'clicked' : selectedLabel }]" />
    </div>
  </nav>
</template>

<script>
import { WptvMenu, WptvMenuItem } from '@wptv/components/ui/menu'
import { WptvDropdown } from '@wptv/components/ui/dropdown'
import capitalizeFirstLetter from '@wptv/filters/src/filters/capitalizeFirstLetter/capitalizeFirstLetter.filter'

export default {
  name: 'DesktopMenu',
  components: {
    WptvMenu,
    WptvMenuItem,
    WptvDropdown
  },
  props: {
    menu: {
      type: Array,
      required: true
    },
    sticked: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      menuToolbarWidth: 0,
      itemsMenu: [],
      nbVisible: this.menu.length,
      breakWidths: [],
      currentItemSelected: null,
      selectedLabel: null
    }
  },
  computed: {
    visibleMenu () {
      return this.menu.slice(0, this.nbVisible)
    },
    // hidden in 'Plus' element
    hiddenMenu () {
      return this.menu.slice(this.nbVisible).map((e) => { return { ...e, label: capitalizeFirstLetter(e.label) } })
    },
    defaultFilter () {
      return this.hiddenMenu.find(item => item.id === this.$route.params.genreId) || {}
    },
    ariaSideMenuLabel () {
      return this.$t(
        'general.menu.a11y.menu_lateral',
        { label: this.selectedLabel ?? this.$t('general.menu.default_menu_lateral'), nb: this.hiddenMenu.length })
    }
  },
  watch: {
    '$route' () {
      this.currentItemSelected = this.$router.currentRoute.path
    }
  },
  mounted () {
    this.currentItemSelected = this.$router.currentRoute.path
    // check on resize
    window.addEventListener('scroll', this.check)
    window.addEventListener('resize', this.check)
    // first check
    this.$nextTick(this.check)
  },
  methods: {
    // check call when resize
    check () {
      // // initial state
      if (this.$refs.leftSide?.$el) {
        this.itemsMenu = this.$refs.leftSide.$el.querySelectorAll('li.nav-item')
      }
      // transform itemsMenu in real array for forEach
      this.breakWidths = [...this.itemsMenu].map(item => item.offsetWidth)
      this.menuToolbarWidth = this.$refs.nav.getBoundingClientRect().width - 300// so that the title & the first element are never too much close
      let nbToShow = 0
      let menuLength = 0
      for (let i = 0; i < this.breakWidths.length; i++) {
        menuLength += this.breakWidths[i]
        if (menuLength > this.menuToolbarWidth) {
          break
        }
        nbToShow = i + 1
      }
      this.nbVisible = nbToShow

      if (!this.hiddenMenu.find(item => item.label === this.selectedLabel)) {
        this.resetLabel()
      }
      this.isAlreadyInHidden() // in case the user reload on hidden genre
    },
    selectFromDropDown (item) {
      this.selectedLabel = item.label
      this.$router.push({ name: 'Genre', params: { genreId: item.id } })
    },
    resetLabel () {
      this.$refs.dropMenu.resetValue()
      this.selectedLabel = null
    },
    isAlreadyInHidden () {
      const currentGenre = this.currentItemSelected.split('/').pop()

      if (this.hiddenMenu.find(item => item.id === currentGenre)) {
        this.selectedLabel = capitalizeFirstLetter(currentGenre)
        this.$refs.dropMenu.selectedItem = this.$refs.dropMenu.items.find(item => item.id === currentGenre)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.ob1-menu-page {
  display: flex;
  flex-direction: row;
  max-width: 90rem;
  justify-content: space-between;
  height: auto !important;

  .right-side {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    flex-shrink: 0;
    position: relative;

    .clicked {
      border-bottom: 0.3125rem solid #f16e00;
      position: absolute;
      width: 100%;
      bottom: 0;
      left: 0;
      animation: border_anim 400ms linear forwards;

      @keyframes border_anim {
        0%{
          width: 0;
        }
        100%{
          width: 100%;
        }
      }
    }
  }
}
</style>
