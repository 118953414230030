function onClickChannel (channelId) {
  return {
    track_page: 'FIP',
    track_zone: 'detail',
    track_nom: 'chaîne',
    track_type: 'clic',
    track_cible: channelId,
    track_detail: 'clic-chaîne-detail-FIP'
  }
}

function onWatchChannel (liveUrl) {
  return {
    track_page: 'fip',
    track_zone: 'detail',
    track_nom: 'regarder_live',
    track_type: 'clic',
    track_cible: liveUrl,
    track_detail: 'clic-regarder_live-detail-FIP'
  }
}

function onSelectItemFromList (videoId) {
  return {
    track_page: 'liste-videos',
    track_zone: 'liste',
    track_nom: 'choix_emission',
    track_type: 'clic',
    track_cible: videoId,
    track_detail: 'clic-choix_emission-liste-liste-videos'
  }
}

function onClickWatchItemFromList (videoId) {
  return {
    track_page: 'liste-videos',
    track_zone: 'liste',
    track_nom: 'regarder_emission',
    track_type: 'clic',
    track_cible: videoId,
    track_detail: 'clic-regarder_emission-liste-liste-videos'
  }
}

export default {
  onClickChannel,
  onWatchChannel,
  onSelectItemFromList,
  onClickWatchItemFromList
}
