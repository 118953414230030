/**
 * Manage scroll behaviour
 * note: the saved position is null when using router link
 * note: needs to be async due to dom update taking some time and needed for scrolling
 * @param to
 * @param from
 * @param savedPosition
 * @returns {Promise<unknown>}
 */
export default function (to, from, savedPosition) {
  return new Promise((resolve) => {
    setTimeout(() => {
      const navigationNode = this.app.$root.$store.getters['navik/getNodeToRestore']
      // Keep position
      let position = to.path !== from.path && savedPosition ? savedPosition : false

      // pages to go back to the top
      if (!savedPosition && ['FipVideo', 'FipGroup', 'Corner', 'Genre', 'Rubric'].includes(to.name)) {
        position = { x: 0, y: 0 }
      } else if (navigationNode && navigationNode.state.lazyHeights.length === 0) {
        position = {
          x: navigationNode.state.scrollPosition.x,
          y: navigationNode.state.scrollPosition.y
        }
      }

      resolve(position)
    }, 50)
  })
}
