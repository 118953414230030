import global from './global'
import utils from './utils'
import menu from './menu'
import header from './header'
import home from './home'
import player from './player'
import cornerChannel from './cornerChannel'
import herozone from './herozone'
import fip from './fip'
import sort from './sort'
import error from './error'

export default {
  global,
  utils,
  home,
  menu,
  header,
  player,
  cornerChannel,
  herozone,
  fip,
  sort,
  error
}
