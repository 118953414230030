import { defineStore } from 'pinia'
import { useErrorStore } from '../errors/errorStore'

export const useChannelStore = defineStore('channel', {
  state: () => ({
    channels: [],
    liveChannels: {
      channels: []
    },
    category: {}
  }),
  actions: {
    async fetchLiveChannels (everywherePopulation) {
      return await this.$nuxt.app.$api.tvm.channels.getLiveChannels(everywherePopulation)
        .then((liveChannels) => {
          this.liveChannels = liveChannels
        })
        .catch(error => useErrorStore().setAxiosError(error))
    },
    async fetchChannels () {
      return await this.$nuxt.app.$api.tvm.channels.getChannels()
        .then((channels) => {
          this.channels = channels
        })
        .catch(error => useErrorStore().setAxiosError(error))
    },
    async fetchChannelById (id: String) {
      return await this.$nuxt.app.$api.tvm.channels.getChannel(id)
        .then(channel => channel)
        .catch(error => useErrorStore().setAxiosError(error))
    },
    async fetchCategory (channelId: any, categoryId: any) {
      return await this.$nuxt.app.$api.tvm.channels.getChannelCategory(channelId, categoryId)
        .then((category) => {
          this.category = category
        })
        .catch(error => useErrorStore().setAxiosError(error))
    }
  },
  getters: {
    getChannelById: (state) => {
      return (channelId: String) => {
        return state.channels.find((channel: any) => channel.id === channelId) || {}
      }
    },
    getLiveChannelById: (state) => {
      return (channelId: String) => {
        return state.liveChannels?.channels?.find((channel: any) => channel.catchupChannelId === channelId) || {}
      }
    }
  }
})
