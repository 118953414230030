function onClickHome () {
  return {
    track_page: 'all',
    track_zone: 'menu',
    track_nom: 'toutes_les_chaînes',
    track_type: 'clic',
    track_cible: '/',
    track_detail: 'clic-replay-menu-all'
  }
}
function onClickHomeReplay () {
  return {
    track_page: 'all',
    track_zone: 'menu',
    track_nom: 'accueil',
    track_type: 'clic',
    track_cible: '/',
    track_detail: 'clic-replay-menu-all'
  }
}

function onClickGenre (label, id) {
  return {
    track_page: 'all',
    track_zone: 'menu',
    track_nom: label,
    track_type: 'clic',
    track_cible: '/genres/' + id,
    track_detail: 'clic-' + label + '-menu-all'
  }
}

function onClickMore () {
  return {
    track_page: 'all',
    track_zone: 'menu',
    track_nom: 'plus',
    track_type: 'clic',
    track_cible: '',
    track_detail: 'clic-plus-menu-all'
  }
}

function onClickOnAriane (route) {
  return {
    track_page: 'all',
    track_zone: 'menu',
    track_nom: 'ariane',
    track_type: 'clic',
    track_cible: route,
    track_detail: 'clic-ariane-menu-all'
  }
}

export default {
  onClickHome,
  onClickGenre,
  onClickMore,
  onClickOnAriane,
  onClickHomeReplay
}
