export default {
  player: {
    PLAYER_WINDOW_CLOSE_BUTTON_LABEL: '',
    PLAYER_WINDOW_FAST_FORWARD_LABEL: 'Avance rapide',
    PLAYER_WINDOW_FAST_REWIND_LABEL: 'Retour rapide',
    PLAYER_BINAURAL_OFF_TOOLTIP: 'Désactiver son 3D',
    PLAYER_BINAURAL_ON_TOOLTIP: 'Activer son 3D',
    PLAYER_WINDOW_INFOBAR_CURRENT_PROGRAM_DEFAULT_TITLE: 'Pas d\'infos sur le programme courant',
    PLAYER_MENUS_SUMMARY_LABEL: 'Résumé',
    PLAYER_MENUS_MOSAIC_LABEL: 'Liste des chaînes',
    PLAYER_MENUS_LANGUAGE_LABEL: 'Langue',
    PLAYER_MENUS_HELP_LABEL: 'Aide',
    PLAYER_MENUS_DISPLAY_LABEL: 'Mode d\'affichage',
    PLAYER_MENUS_VIDEO_QUALITY_LABEL: 'Qualité vidéo',
    PLAYER_SUMMARY_DURATION_LABEL: 'Durée : ',
    PLAYER_SUMMARY_DURATION_UNIT_LABEL: 'minutes',
    PLAYER_SUMMARY_HAS_NO_INFORMATION_LABEL: 'Information indisponible',
    PLAYER_SUMMARY_ACTION_BUTTON_LABEL: 'Recommencer du début',
    PLAYER_SUMMARY_DIRECTOR_LABEL: 'Réalisé par ',
    PLAYER_SUMMARY_ACTORS_LABEL: 'Avec ',
    PLAYER_MOZAIC_UNAVAILABLE: 'Information indisponible',
    PLAYER_LANGUAGE_LANGUAGE_CHOICE_LABEL: 'Choix de la langue',
    PLAYER_LANGUAGE_SUBTITLE_CHOICE_LABEL: 'Choix des sous-titres',
    PLAYER_LANGUAGE_AUDIO_CHOICE_LABEL: 'Audiodescription',
    PLAYER_LANGUAGE_NOTHING_CHOICE_LABEL: 'Aucun',
    PLAYER_LANGUAGE_HEARING_CHOICE_LABEL: 'Malentendant',
    PLAYER_LANGUAGE_LANGS_FRA: 'Français',
    PLAYER_LANGUAGE_LANGS_DEU: 'Allemand',
    PLAYER_LANGUAGE_LANGS_ENG: 'Anglais',
    PLAYER_LANGUAGE_LANGS_NONE: 'Aucun',
    PLAYER_LANGUAGE_SUBTITLE_SIZE_LABEL: 'Taille des sous-titres',
    PLAYER_LANGUAGE_SUBTITLE_COLOR_TEXT: 'Couleur du texte',
    PLAYER_LANGUAGE_SUBTITLE_DEFAULT_COLOR: 'Couleur par défaut',
    PLAYER_LANGUAGE_SUBTITLE_FONT_TEXT: 'Famille de police',
    PLAYER_LANGUAGE_SUBTITLE_DEFAULT_FONT_TEXT: 'Police par défaut',
    PLAYER_HELP_FULLSCREEN_TITLE_LABEL: 'Sortir du plein écran',
    PLAYER_HELP_FULLSCREEN_DESCRIPTION_LABEL: '',
    PLAYER_HELP_INFO_PRG_SUMMARY_TITLE_LABEL: 'Info programme/résumé',
    PLAYER_HELP_INFO_PRG_SUMMARY_DESCRIPTION_LABEL: '1er appui : Info programme, 2ème appui : Résumé du programme, 3ème appui : Retour vidéo',
    PLAYER_HELP_KEY_SPACE_TITLE_LABEL: 'Lecture / pause',
    PLAYER_HELP_KEY_SPACE_DESCRIPTION_LABEL: '',
    PLAYER_HELP_NAVIGATION_CHANNEL_TITLE_LABEL: 'Chaîne précédente / chaîne suivante',
    PLAYER_HELP_NAVIGATION_CHANNEL_DESCRIPTION_LABEL: '',
    PLAYER_HELP_VOLUME_TITLE_LABEL: 'Régler le volume',
    PLAYER_HELP_VOLUME_DESCRIPTION_LABEL: '',
    PLAYER_HELP_MOSAIC_TITLE_LABEL: 'Afficher la liste des chaînes',
    PLAYER_HELP_MOSAIC_DESCRIPTION_LABEL: '',
    PLAYER_HELP_SUMMARY_TITLE_LABEL: 'Résumé du programme en cours',
    PLAYER_HELP_SUMMARY_DESCRIPTION_LABEL: '',
    PLAYER_HELP_KEY_NAVIGATION_TITLE_LABEL: 'Sélection des fonctions du menu',
    PLAYER_HELP_KEY_NAVIGATION_DESCRIPTION_LABEL: 'Appuyez sur Entrée pour valider votre sélection ',
    PLAYER_SETTINGS_DISPLAY_TITLE_LABEL: 'Ajustez l\'affichage à votre écran',
    PLAYER_SETTINGS_DISPLAY_NORMAL_LABEL: 'Normal',
    PLAYER_SETTINGS_DISPLAY_STRETCH_LABEL: 'Etiré',
    PLAYER_SETTINGS_QUALITY_TITLE_LABEL: 'La qualité vidéo s\'adapte au débit Internet',
    PLAYER_SETTINGS_QUALITY_HIGH_LABEL: 'Haute',
    PLAYER_SETTINGS_QUALITY_RECEIVED_LABEL: 'Reçue',
    PLAYER_SETTINGS_QUALITY_LOW_LABEL: 'Basse',
    PLAYER_SETTINGS_QUALITY_LOW_UNIT: 'Mbits',
    PLAYER_SETTINGS_QUALITY_HIGH_UNIT: 'Mbits',
    PLAYER_INFO_BANNER_AVAILABLE_END: 'disponible encore {0} jours',
    PLAYER_INFO_BANNER_DURATION: '{0} min',
    PLAYER_INFO_BANNER_TOOLTIP_CSA10_LABEL: 'déconseillé aux moins de 10 ans',
    PLAYER_INFO_BANNER_TOOLTIP_CSA12_LABEL: 'déconseillé aux moins de 12 ans',
    PLAYER_INFO_BANNER_TOOLTIP_CSA16_LABEL: 'déconseillé aux moins de 16 ans',
    PLAYER_INFO_BANNER_TOOLTIP_CSA18_LABEL: 'déconseillé aux moins de 18 ans',
    PLAYER_INFO_BANNER_TOOLTIP_AUDIO_DESC_LABEL: 'Audio-description disponible',
    PLAYER_INFO_BANNER_TOOLTIP_VO_LABEL: 'Version originale disponible',
    PLAYER_INFO_BANNER_TOOLTIP_HEARING_IMPAIRED_LABEL: 'Version malentendant disponible',
    PLAYER_INFO_BANNER_TOOLTIP_MULTILANG_LABEL: 'Multi-langues disponible',
    PLAYER_INFO_BANNER_TOOLTIP_VOST_LABEL: 'Version Originale Sous-titrée disponible',
    PLAYER_CONTROL_BAR_TOOLTIP_BUTTON_MENU_LABEL: 'Menu',
    PLAYER_CONTROL_BAR_TOOLTIP_BUTTON_SETTINGS_LABEL: 'Réglages',
    PLAYER_CONTROL_BAR_TOOLTIP_BUTTON_FAST_FORWARD_LABEL: 'Avance rapide',
    PLAYER_CONTROL_BAR_TOOLTIP_BUTTON_FAST_REWIND_LABEL: 'Retour rapide',
    PLAYER_CONTROL_BAR_TOOLTIP_BUTTON_STEP_FORWARD_LABEL: '+ 15 secondes',
    PLAYER_CONTROL_BAR_TOOLTIP_BUTTON_STEP_REWIND_LABEL: '- 15 secondes',
    PLAYER_CONTROL_BAR_TOOLTIP_BUTTON_VOLUME_LABEL: 'Volume',
    PLAYER_CONTROL_BAR_TOOLTIP_BUTTON_MUTE_LABEL: 'Muet',
    PLAYER_CONTROL_BAR_TOOLTIP_BUTTON_STOP_VOLUME_LABEL: 'Muet',
    PLAYER_CONTROL_BAR_TOOLTIP_BUTTON_FULLSCREEN_LABEL: 'Plein écran',
    PLAYER_CONTROL_BAR_TOOLTIP_BUTTON_NORMALSCREEN_LABEL: 'Quitter le plein écran',
    PLAYER_CONTROL_BAR_TOOLTIP_BUTTON_OFFSCREEN_LABEL: 'Mode vignette',
    PLAYER_CONTROL_BAR_TOOLTIP_BUTTON_INTERNALSCREEN_LABEL: 'Mode normal',
    PLAYER_CONTROL_BAR_TOOLTIP_BUTTON_PLAY_LABEL: 'Lecture',
    PLAYER_CONTROL_BAR_TOOLTIP_BUTTON_PAUSE_LABEL: 'Pause',
    PLAYER_CONTROL_BAR_TOOLTIP_BUTTON_REPLAY_LABEL: 'Rejouer',
    PLAYER_CONTROL_BAR_TOOLTIP_BUTTON_STOP_LABEL: 'Stop',
    PLAYER_CONTROL_BAR_TIME_ELAPSED: 'temps écoulé',
    PLAYER_CONTROL_BAR_TIME_REMAINING: 'temps restant',
    PLAYER_MESSAGE_MORE_INFO_LABEL: 'Plus d\'informations',
    PLAYER_MESSAGE_REPLAY_LABEL: 'Relancer la lecture',
    PLAYER_MESSAGE_ACKNOLEDGE_BUTTON_LABEL: 'OK',
    PLAYER_MESSAGE_NEW_WINDOW: 'Nouvelle Fenêtre',
    PLAYER_BANNER_TOOLTIP_CSA10_LABEL: 'déconseillé aux moins de 10 ans',
    PLAYER_BANNER_TOOLTIP_CSA12_LABEL: 'déconseillé aux moins de 12 ans',
    PLAYER_BANNER_TOOLTIP_CSA16_LABEL: 'déconseillé aux moins de 16 ans',
    PLAYER_BANNER_TOOLTIP_CSA18_LABEL: 'déconseillé aux moins de 18 ans'
  },
  common_languages: {
    COMMON_LANGUAGES_AUDIODESC: 'audio-description en français',
    COMMON_LANGUAGES_LOCALE_AAR: 'afar',
    COMMON_LANGUAGES_LOCALE_ABK: 'abkhaze',
    COMMON_LANGUAGES_LOCALE_ACE: 'aceh',
    COMMON_LANGUAGES_LOCALE_ACH: 'acoli',
    COMMON_LANGUAGES_LOCALE_ADA: 'adangme',
    COMMON_LANGUAGES_LOCALE_ADY: 'adyghé',
    COMMON_LANGUAGES_LOCALE_AFA: 'afro-asiatiques, langues',
    COMMON_LANGUAGES_LOCALE_AFH: 'afrihili',
    COMMON_LANGUAGES_LOCALE_AFR: 'afrikaans',
    COMMON_LANGUAGES_LOCALE_AIN: 'aïnou',
    COMMON_LANGUAGES_LOCALE_AKA: 'akan',
    COMMON_LANGUAGES_LOCALE_AKK: 'akkadien',
    COMMON_LANGUAGES_LOCALE_ALB_SQI: 'albanais',
    COMMON_LANGUAGES_LOCALE_ALE: 'aléoute',
    COMMON_LANGUAGES_LOCALE_ALG: 'algonquines, langues',
    COMMON_LANGUAGES_LOCALE_ALT: 'altai du sud',
    COMMON_LANGUAGES_LOCALE_AMH: 'amharique',
    COMMON_LANGUAGES_LOCALE_ANG: 'anglo-saxon (ca.450-1100)',
    COMMON_LANGUAGES_LOCALE_ANP: 'angika',
    COMMON_LANGUAGES_LOCALE_APA: 'apaches, langues',
    COMMON_LANGUAGES_LOCALE_ARA: 'arabe',
    COMMON_LANGUAGES_LOCALE_ARC: 'araméen d\'empire (700-300 BCE)',
    COMMON_LANGUAGES_LOCALE_ARG: 'aragonais',
    COMMON_LANGUAGES_LOCALE_ARM_HYE: 'arménien',
    COMMON_LANGUAGES_LOCALE_ARN: 'mapudungun',
    COMMON_LANGUAGES_LOCALE_ARP: 'arapaho',
    COMMON_LANGUAGES_LOCALE_ART: 'artificielles, langues',
    COMMON_LANGUAGES_LOCALE_ARW: 'arawak',
    COMMON_LANGUAGES_LOCALE_ASM: 'assamais',
    COMMON_LANGUAGES_LOCALE_AST: 'léonais',
    COMMON_LANGUAGES_LOCALE_ATH: 'athapascanes, langues',
    COMMON_LANGUAGES_LOCALE_AUS: 'australiennes, langues',
    COMMON_LANGUAGES_LOCALE_AVA: 'avar',
    COMMON_LANGUAGES_LOCALE_AVE: 'avestique',
    COMMON_LANGUAGES_LOCALE_AWA: 'awadhi',
    COMMON_LANGUAGES_LOCALE_AYM: 'aymara',
    COMMON_LANGUAGES_LOCALE_AZE: 'azéri',
    COMMON_LANGUAGES_LOCALE_BAD: 'banda, langues',
    COMMON_LANGUAGES_LOCALE_BAI: 'bamiléké, langues',
    COMMON_LANGUAGES_LOCALE_BAK: 'bachkir',
    COMMON_LANGUAGES_LOCALE_BAL: 'baloutchi',
    COMMON_LANGUAGES_LOCALE_BAM: 'bambara',
    COMMON_LANGUAGES_LOCALE_BAN: 'balinais',
    COMMON_LANGUAGES_LOCALE_BAQ_EUS: 'basque',
    COMMON_LANGUAGES_LOCALE_BAS: 'basa',
    COMMON_LANGUAGES_LOCALE_BAT: 'baltes, langues',
    COMMON_LANGUAGES_LOCALE_BEJ: 'bedja',
    COMMON_LANGUAGES_LOCALE_BEL: 'biélorusse',
    COMMON_LANGUAGES_LOCALE_BEM: 'bemba',
    COMMON_LANGUAGES_LOCALE_BEN: 'bengali',
    COMMON_LANGUAGES_LOCALE_BER: 'berbères, langues',
    COMMON_LANGUAGES_LOCALE_BHO: 'bhojpuri',
    COMMON_LANGUAGES_LOCALE_BIH: 'langues biharis',
    COMMON_LANGUAGES_LOCALE_BIK: 'bikol',
    COMMON_LANGUAGES_LOCALE_BIN: 'edo',
    COMMON_LANGUAGES_LOCALE_BIS: 'bichlamar',
    COMMON_LANGUAGES_LOCALE_BLA: 'blackfoot',
    COMMON_LANGUAGES_LOCALE_BNT: 'bantou, langues',
    COMMON_LANGUAGES_LOCALE_BOS: 'bosniaque',
    COMMON_LANGUAGES_LOCALE_BRA: 'braj',
    COMMON_LANGUAGES_LOCALE_BRE: 'breton',
    COMMON_LANGUAGES_LOCALE_BTK: 'batak, langues',
    COMMON_LANGUAGES_LOCALE_BUA: 'bouriate',
    COMMON_LANGUAGES_LOCALE_BUG: 'bugi',
    COMMON_LANGUAGES_LOCALE_BUL: 'bulgare',
    COMMON_LANGUAGES_LOCALE_BUR_MYA: 'birman',
    COMMON_LANGUAGES_LOCALE_BYN: 'blin',
    COMMON_LANGUAGES_LOCALE_CAD: 'caddo',
    COMMON_LANGUAGES_LOCALE_CAI: 'amérindiennes de l\'Amérique centrale, langues',
    COMMON_LANGUAGES_LOCALE_CAR: 'karib',
    COMMON_LANGUAGES_LOCALE_CAT: 'valencien',
    COMMON_LANGUAGES_LOCALE_CAU: 'caucasiennes, langues',
    COMMON_LANGUAGES_LOCALE_CEB: 'cebuano',
    COMMON_LANGUAGES_LOCALE_CEL: 'celtiques, langues',
    COMMON_LANGUAGES_LOCALE_CHA: 'chamorro',
    COMMON_LANGUAGES_LOCALE_CHB: 'chibcha',
    COMMON_LANGUAGES_LOCALE_CHE: 'tchétchène',
    COMMON_LANGUAGES_LOCALE_CHG: 'djaghataï',
    COMMON_LANGUAGES_LOCALE_CHI_ZHO: 'chinois',
    COMMON_LANGUAGES_LOCALE_CHK: 'chuuk',
    COMMON_LANGUAGES_LOCALE_CHM: 'mari',
    COMMON_LANGUAGES_LOCALE_CHN: 'chinook, jargon',
    COMMON_LANGUAGES_LOCALE_CHO: 'choctaw',
    COMMON_LANGUAGES_LOCALE_CHP: 'chipewyan',
    COMMON_LANGUAGES_LOCALE_CHR: 'cherokee',
    COMMON_LANGUAGES_LOCALE_CHU: 'vieux slave',
    COMMON_LANGUAGES_LOCALE_CHV: 'tchouvache',
    COMMON_LANGUAGES_LOCALE_CHY: 'cheyenne',
    COMMON_LANGUAGES_LOCALE_CMC: 'chames, langues',
    COMMON_LANGUAGES_LOCALE_COP: 'copte',
    COMMON_LANGUAGES_LOCALE_COR: 'cornique',
    COMMON_LANGUAGES_LOCALE_COS: 'corse',
    COMMON_LANGUAGES_LOCALE_CPE: 'créoles et pidgins basés sur l\'anglais',
    COMMON_LANGUAGES_LOCALE_CPF: 'créoles et pidgins basés sur le français',
    COMMON_LANGUAGES_LOCALE_CPP: 'créoles et pidgins basés sur le portugais',
    COMMON_LANGUAGES_LOCALE_CRE: 'cree',
    COMMON_LANGUAGES_LOCALE_CRH: 'tatar de Crimé',
    COMMON_LANGUAGES_LOCALE_CRP: 'créoles et pidgins',
    COMMON_LANGUAGES_LOCALE_CSB: 'kachoube',
    COMMON_LANGUAGES_LOCALE_CUS: 'couchitiques, langues',
    COMMON_LANGUAGES_LOCALE_CZE_CES: 'tchèque',
    COMMON_LANGUAGES_LOCALE_DAK: 'dakota',
    COMMON_LANGUAGES_LOCALE_DAN: 'danois',
    COMMON_LANGUAGES_LOCALE_DAR: 'dargwa',
    COMMON_LANGUAGES_LOCALE_DAY: 'dayak, langues',
    COMMON_LANGUAGES_LOCALE_DEL: 'delaware',
    COMMON_LANGUAGES_LOCALE_DEN: 'esclave (athapascan)',
    COMMON_LANGUAGES_LOCALE_DGR: 'dogrib',
    COMMON_LANGUAGES_LOCALE_DIN: 'dinka',
    COMMON_LANGUAGES_LOCALE_DIV: 'maldivien',
    COMMON_LANGUAGES_LOCALE_DOI: 'dogri',
    COMMON_LANGUAGES_LOCALE_DRA: 'dravidiennes, langues',
    COMMON_LANGUAGES_LOCALE_DSB: 'bas-sorabe',
    COMMON_LANGUAGES_LOCALE_DUA: 'douala',
    COMMON_LANGUAGES_LOCALE_DUM: 'néerlandais moyen (ca. 1050-1350)',
    COMMON_LANGUAGES_LOCALE_DUT_NLD: 'néerlandais',
    COMMON_LANGUAGES_LOCALE_DYU: 'dioula',
    COMMON_LANGUAGES_LOCALE_DZO: 'dzongkha',
    COMMON_LANGUAGES_LOCALE_EFI: 'efik',
    COMMON_LANGUAGES_LOCALE_EGY: 'égyptien',
    COMMON_LANGUAGES_LOCALE_EKA: 'ekajuk',
    COMMON_LANGUAGES_LOCALE_ELX: 'élamite',
    COMMON_LANGUAGES_LOCALE_EN: 'anglais',
    COMMON_LANGUAGES_LOCALE_ENG: 'anglais',
    COMMON_LANGUAGES_LOCALE_ENM: 'anglais moyen (1100-1500)',
    COMMON_LANGUAGES_LOCALE_EPO: 'espéranto',
    COMMON_LANGUAGES_LOCALE_ES: 'espagnol',
    COMMON_LANGUAGES_LOCALE_EST: 'estonien',
    COMMON_LANGUAGES_LOCALE_EWE: 'éwé',
    COMMON_LANGUAGES_LOCALE_EWO: 'éwondo',
    COMMON_LANGUAGES_LOCALE_FAN: 'fang',
    COMMON_LANGUAGES_LOCALE_FAO: 'féroïen',
    COMMON_LANGUAGES_LOCALE_FAT: 'fanti',
    COMMON_LANGUAGES_LOCALE_FIJ: 'fidjien',
    COMMON_LANGUAGES_LOCALE_FIL: 'pilipino',
    COMMON_LANGUAGES_LOCALE_FIN: 'finnois',
    COMMON_LANGUAGES_LOCALE_FIU: 'finno-ougriennes, langues',
    COMMON_LANGUAGES_LOCALE_FON: 'fon',
    COMMON_LANGUAGES_LOCALE_FR: 'français',
    COMMON_LANGUAGES_LOCALE_FRA: 'français',
    COMMON_LANGUAGES_LOCALE_FRA_FRE: 'français',
    COMMON_LANGUAGES_LOCALE_FRE: 'français',
    COMMON_LANGUAGES_LOCALE_FRE_FRA: 'français',
    COMMON_LANGUAGES_LOCALE_FRE_SUB: 'français malentendant',
    COMMON_LANGUAGES_LOCALE_FRM: 'français moyen (1400-1600)',
    COMMON_LANGUAGES_LOCALE_FRO: 'français ancien (842-ca.1400)',
    COMMON_LANGUAGES_LOCALE_FRR: 'frison septentrional',
    COMMON_LANGUAGES_LOCALE_FRS: 'frison oriental',
    COMMON_LANGUAGES_LOCALE_FRY: 'frison occidental',
    COMMON_LANGUAGES_LOCALE_FUL: 'peul',
    COMMON_LANGUAGES_LOCALE_FUR: 'frioulan',
    COMMON_LANGUAGES_LOCALE_GAA: 'ga',
    COMMON_LANGUAGES_LOCALE_GAY: 'gayo',
    COMMON_LANGUAGES_LOCALE_GBA: 'gbaya',
    COMMON_LANGUAGES_LOCALE_GEM: 'germaniques, langues',
    COMMON_LANGUAGES_LOCALE_GEO_KAT: 'géorgien',
    COMMON_LANGUAGES_LOCALE_GER_DEU: 'allemand',
    COMMON_LANGUAGES_LOCALE_GEZ: 'guèze',
    COMMON_LANGUAGES_LOCALE_GIL: 'kiribati',
    COMMON_LANGUAGES_LOCALE_GLA: 'gaélique écossais',
    COMMON_LANGUAGES_LOCALE_GLE: 'irlandais',
    COMMON_LANGUAGES_LOCALE_GLG: 'galicien',
    COMMON_LANGUAGES_LOCALE_GLV: 'manx',
    COMMON_LANGUAGES_LOCALE_GMH: 'allemand, moyen haut (ca. 1050-1500)',
    COMMON_LANGUAGES_LOCALE_GOH: 'allemand, vieux haut (ca. 750-1050)',
    COMMON_LANGUAGES_LOCALE_GON: 'gond',
    COMMON_LANGUAGES_LOCALE_GOR: 'gorontalo',
    COMMON_LANGUAGES_LOCALE_GOT: 'gothique',
    COMMON_LANGUAGES_LOCALE_GRB: 'grebo',
    COMMON_LANGUAGES_LOCALE_GRC: 'grec ancien (jusqu\'à 1453)',
    COMMON_LANGUAGES_LOCALE_GRE_ELL: 'grec moderne (après 1453)',
    COMMON_LANGUAGES_LOCALE_GRN: 'guarani',
    COMMON_LANGUAGES_LOCALE_GSW: 'suisse alémanique',
    COMMON_LANGUAGES_LOCALE_GUJ: 'goudjrati',
    COMMON_LANGUAGES_LOCALE_GWI: 'gwich\'in',
    COMMON_LANGUAGES_LOCALE_HAI: 'haida',
    COMMON_LANGUAGES_LOCALE_HAT: 'haïtien',
    COMMON_LANGUAGES_LOCALE_HAU: 'haoussa',
    COMMON_LANGUAGES_LOCALE_HAW: 'hawaïen',
    COMMON_LANGUAGES_LOCALE_HEB: 'hébreu',
    COMMON_LANGUAGES_LOCALE_HER: 'herero',
    COMMON_LANGUAGES_LOCALE_HIL: 'hiligaynon',
    COMMON_LANGUAGES_LOCALE_HIM: 'langues paharis occidentales',
    COMMON_LANGUAGES_LOCALE_HIN: 'hindi',
    COMMON_LANGUAGES_LOCALE_HIT: 'hittite',
    COMMON_LANGUAGES_LOCALE_HMN: 'hmong',
    COMMON_LANGUAGES_LOCALE_HMO: 'hiri motu',
    COMMON_LANGUAGES_LOCALE_HRV: 'croate',
    COMMON_LANGUAGES_LOCALE_HSB: 'haut-sorabe',
    COMMON_LANGUAGES_LOCALE_HUN: 'hongrois',
    COMMON_LANGUAGES_LOCALE_HUP: 'hupa',
    COMMON_LANGUAGES_LOCALE_IBA: 'iban',
    COMMON_LANGUAGES_LOCALE_IBO: 'igbo',
    COMMON_LANGUAGES_LOCALE_ICE_ISL: 'islandais',
    COMMON_LANGUAGES_LOCALE_IDO: 'ido',
    COMMON_LANGUAGES_LOCALE_III: 'yi de Sichuan',
    COMMON_LANGUAGES_LOCALE_IJO: 'ijo, langues',
    COMMON_LANGUAGES_LOCALE_IKU: 'inuktitut',
    COMMON_LANGUAGES_LOCALE_ILE: 'interlingue',
    COMMON_LANGUAGES_LOCALE_ILO: 'ilocano',
    COMMON_LANGUAGES_LOCALE_INA: 'interlingua (langue auxiliaire internationale)',
    COMMON_LANGUAGES_LOCALE_INC: 'indo-aryennes, langues',
    COMMON_LANGUAGES_LOCALE_IND: 'indonésien',
    COMMON_LANGUAGES_LOCALE_INE: 'indo-européennes, langues',
    COMMON_LANGUAGES_LOCALE_INH: 'ingouche',
    COMMON_LANGUAGES_LOCALE_IPK: 'inupiaq',
    COMMON_LANGUAGES_LOCALE_IRA: 'iraniennes, langues',
    COMMON_LANGUAGES_LOCALE_IRO: 'iroquoises, langues',
    COMMON_LANGUAGES_LOCALE_ITA: 'italien',
    COMMON_LANGUAGES_LOCALE_JAV: 'javanais',
    COMMON_LANGUAGES_LOCALE_JBO: 'lojban',
    COMMON_LANGUAGES_LOCALE_JPN: 'japonais',
    COMMON_LANGUAGES_LOCALE_JPR: 'judéo-persan',
    COMMON_LANGUAGES_LOCALE_JRB: 'judéo-arabe',
    COMMON_LANGUAGES_LOCALE_KAA: 'karakalpak',
    COMMON_LANGUAGES_LOCALE_KAB: 'kabyle',
    COMMON_LANGUAGES_LOCALE_KAC: 'kachin',
    COMMON_LANGUAGES_LOCALE_KAL: 'groenlandais',
    COMMON_LANGUAGES_LOCALE_KAM: 'kamba',
    COMMON_LANGUAGES_LOCALE_KAN: 'kannada',
    COMMON_LANGUAGES_LOCALE_KAR: 'karen, langues',
    COMMON_LANGUAGES_LOCALE_KAS: 'kashmiri',
    COMMON_LANGUAGES_LOCALE_KAU: 'kanouri',
    COMMON_LANGUAGES_LOCALE_KAW: 'kawi',
    COMMON_LANGUAGES_LOCALE_KAZ: 'kazakh',
    COMMON_LANGUAGES_LOCALE_KBD: 'kabardien',
    COMMON_LANGUAGES_LOCALE_KHA: 'khasi',
    COMMON_LANGUAGES_LOCALE_KHI: 'khoïsan, langues',
    COMMON_LANGUAGES_LOCALE_KHM: 'khmer central',
    COMMON_LANGUAGES_LOCALE_KHO: 'sakan',
    COMMON_LANGUAGES_LOCALE_KIK: 'kikuyu',
    COMMON_LANGUAGES_LOCALE_KIN: 'rwanda',
    COMMON_LANGUAGES_LOCALE_KIR: 'kirghiz',
    COMMON_LANGUAGES_LOCALE_KMB: 'kimbundu',
    COMMON_LANGUAGES_LOCALE_KOK: 'konkani',
    COMMON_LANGUAGES_LOCALE_KOM: 'kom',
    COMMON_LANGUAGES_LOCALE_KON: 'kongo',
    COMMON_LANGUAGES_LOCALE_KOR: 'coréen',
    COMMON_LANGUAGES_LOCALE_KOS: 'kosrae',
    COMMON_LANGUAGES_LOCALE_KPE: 'kpellé',
    COMMON_LANGUAGES_LOCALE_KRC: 'karatchai balkar',
    COMMON_LANGUAGES_LOCALE_KRL: 'carélien',
    COMMON_LANGUAGES_LOCALE_KRO: 'krou, langues',
    COMMON_LANGUAGES_LOCALE_KRU: 'kurukh',
    COMMON_LANGUAGES_LOCALE_KUA: 'kwanyama',
    COMMON_LANGUAGES_LOCALE_KUM: 'koumyk',
    COMMON_LANGUAGES_LOCALE_KUR: 'kurde',
    COMMON_LANGUAGES_LOCALE_KUT: 'kutenai',
    COMMON_LANGUAGES_LOCALE_LAD: 'judéo-espagnol',
    COMMON_LANGUAGES_LOCALE_LAH: 'lahnda',
    COMMON_LANGUAGES_LOCALE_LAM: 'lamba',
    COMMON_LANGUAGES_LOCALE_LAO: 'lao',
    COMMON_LANGUAGES_LOCALE_LAT: 'latin',
    COMMON_LANGUAGES_LOCALE_LAV: 'letton',
    COMMON_LANGUAGES_LOCALE_LEZ: 'lezghien',
    COMMON_LANGUAGES_LOCALE_LIM: 'limbourgeois',
    COMMON_LANGUAGES_LOCALE_LIN: 'lingala',
    COMMON_LANGUAGES_LOCALE_LIT: 'lituanien',
    COMMON_LANGUAGES_LOCALE_LOL: 'mongo',
    COMMON_LANGUAGES_LOCALE_LOZ: 'lozi',
    COMMON_LANGUAGES_LOCALE_LTZ: 'luxembourgeois',
    COMMON_LANGUAGES_LOCALE_LUA: 'luba-lulua',
    COMMON_LANGUAGES_LOCALE_LUB: 'luba-katanga',
    COMMON_LANGUAGES_LOCALE_LUG: 'ganda',
    COMMON_LANGUAGES_LOCALE_LUI: 'luiseno',
    COMMON_LANGUAGES_LOCALE_LUN: 'lunda',
    COMMON_LANGUAGES_LOCALE_LUO: 'luo (Kenya et Tanzanie)',
    COMMON_LANGUAGES_LOCALE_LUS: 'lushai',
    COMMON_LANGUAGES_LOCALE_MAC_MKD: 'macédonien',
    COMMON_LANGUAGES_LOCALE_MAD: 'madourais',
    COMMON_LANGUAGES_LOCALE_MAG: 'magahi',
    COMMON_LANGUAGES_LOCALE_MAH: 'marshall',
    COMMON_LANGUAGES_LOCALE_MAI: 'maithili',
    COMMON_LANGUAGES_LOCALE_MAK: 'makassar',
    COMMON_LANGUAGES_LOCALE_MAL: 'malayalam',
    COMMON_LANGUAGES_LOCALE_MAN: 'mandingue',
    COMMON_LANGUAGES_LOCALE_MAO_MRI: 'maori',
    COMMON_LANGUAGES_LOCALE_MAP: 'austronésiennes, langues',
    COMMON_LANGUAGES_LOCALE_MAR: 'marathe',
    COMMON_LANGUAGES_LOCALE_MAS: 'massaï',
    COMMON_LANGUAGES_LOCALE_MAY_MSA: 'malais',
    COMMON_LANGUAGES_LOCALE_MDF: 'moksa',
    COMMON_LANGUAGES_LOCALE_MDR: 'mandar',
    COMMON_LANGUAGES_LOCALE_MEN: 'mendé',
    COMMON_LANGUAGES_LOCALE_MGA: 'irlandais moyen (900-1200)',
    COMMON_LANGUAGES_LOCALE_MIC: 'micmac',
    COMMON_LANGUAGES_LOCALE_MIN: 'minangkabau',
    COMMON_LANGUAGES_LOCALE_MIS: 'langues non codées',
    COMMON_LANGUAGES_LOCALE_MKH: 'môn-khmer, langues',
    COMMON_LANGUAGES_LOCALE_MLG: 'malgache',
    COMMON_LANGUAGES_LOCALE_MLT: 'maltais',
    COMMON_LANGUAGES_LOCALE_MNC: 'mandchou',
    COMMON_LANGUAGES_LOCALE_MNI: 'manipuri',
    COMMON_LANGUAGES_LOCALE_MNO: 'manobo, langues',
    COMMON_LANGUAGES_LOCALE_MOH: 'mohawk',
    COMMON_LANGUAGES_LOCALE_MON: 'mongol',
    COMMON_LANGUAGES_LOCALE_MOS: 'moré',
    COMMON_LANGUAGES_LOCALE_MUL: 'multilingue',
    COMMON_LANGUAGES_LOCALE_MUN: 'mounda, langues',
    COMMON_LANGUAGES_LOCALE_MUS: 'muskogee',
    COMMON_LANGUAGES_LOCALE_MWL: 'mirandais',
    COMMON_LANGUAGES_LOCALE_MWR: 'marvari',
    COMMON_LANGUAGES_LOCALE_MYN: 'maya, langues',
    COMMON_LANGUAGES_LOCALE_MYV: 'erza',
    COMMON_LANGUAGES_LOCALE_NAH: 'nahuatl, langues',
    COMMON_LANGUAGES_LOCALE_NAI: 'nord-amérindiennes, langues',
    COMMON_LANGUAGES_LOCALE_NAP: 'napolitain',
    COMMON_LANGUAGES_LOCALE_NAU: 'nauruan',
    COMMON_LANGUAGES_LOCALE_NAV: 'navaho',
    COMMON_LANGUAGES_LOCALE_NBL: 'ndébélé du sud',
    COMMON_LANGUAGES_LOCALE_NDE: 'ndébélé du nord',
    COMMON_LANGUAGES_LOCALE_NDO: 'ndonga',
    COMMON_LANGUAGES_LOCALE_NDS: 'saxon, bas',
    COMMON_LANGUAGES_LOCALE_NEP: 'népalais',
    COMMON_LANGUAGES_LOCALE_NEW: 'newari',
    COMMON_LANGUAGES_LOCALE_NIA: 'nias',
    COMMON_LANGUAGES_LOCALE_NIC: 'nigéro-kordofaniennes, langues',
    COMMON_LANGUAGES_LOCALE_NIU: 'niué',
    COMMON_LANGUAGES_LOCALE_NNO: 'nynorsk, norvégien',
    COMMON_LANGUAGES_LOCALE_NOB: 'norvégien bokmål',
    COMMON_LANGUAGES_LOCALE_NOG: 'nogay',
    COMMON_LANGUAGES_LOCALE_NON: 'norrois, vieux',
    COMMON_LANGUAGES_LOCALE_NOR: 'norvégien',
    COMMON_LANGUAGES_LOCALE_NQO: 'n\'ko',
    COMMON_LANGUAGES_LOCALE_NSO: 'sotho du nord',
    COMMON_LANGUAGES_LOCALE_NUB: 'nubiennes, langues',
    COMMON_LANGUAGES_LOCALE_NWC: 'newari classique',
    COMMON_LANGUAGES_LOCALE_NYA: 'nyanja',
    COMMON_LANGUAGES_LOCALE_NYM: 'nyamwezi',
    COMMON_LANGUAGES_LOCALE_NYN: 'nyankolé',
    COMMON_LANGUAGES_LOCALE_NYO: 'nyoro',
    COMMON_LANGUAGES_LOCALE_NZI: 'nzema',
    COMMON_LANGUAGES_LOCALE_OCI: 'occitan (après 1500)',
    COMMON_LANGUAGES_LOCALE_OJI: 'ojibwa',
    COMMON_LANGUAGES_LOCALE_ORI: 'oriya',
    COMMON_LANGUAGES_LOCALE_ORM: 'galla',
    COMMON_LANGUAGES_LOCALE_OSA: 'osage',
    COMMON_LANGUAGES_LOCALE_OSS: 'ossète',
    COMMON_LANGUAGES_LOCALE_OTA: 'turc ottoman (1500-1928)',
    COMMON_LANGUAGES_LOCALE_OTO: 'otomi, langues',
    COMMON_LANGUAGES_LOCALE_PAA: 'papoues, langues',
    COMMON_LANGUAGES_LOCALE_PAG: 'pangasinan',
    COMMON_LANGUAGES_LOCALE_PAL: 'pahlavi',
    COMMON_LANGUAGES_LOCALE_PAM: 'pampangan',
    COMMON_LANGUAGES_LOCALE_PAN: 'pendjabi',
    COMMON_LANGUAGES_LOCALE_PAP: 'papiamento',
    COMMON_LANGUAGES_LOCALE_PAU: 'palau',
    COMMON_LANGUAGES_LOCALE_PEO: 'perse, vieux (ca. 600-400 av. J.-C.)',
    COMMON_LANGUAGES_LOCALE_PER_FAS: 'persan',
    COMMON_LANGUAGES_LOCALE_PHI: 'philippines, langues',
    COMMON_LANGUAGES_LOCALE_PHN: 'phénicien',
    COMMON_LANGUAGES_LOCALE_PLI: 'pali',
    COMMON_LANGUAGES_LOCALE_POL: 'polonais',
    COMMON_LANGUAGES_LOCALE_PON: 'pohnpei',
    COMMON_LANGUAGES_LOCALE_POR: 'portugais',
    COMMON_LANGUAGES_LOCALE_PRA: 'prâkrit, langues',
    COMMON_LANGUAGES_LOCALE_PRO: 'provençal ancien (jusqu\'à 1500)',
    COMMON_LANGUAGES_LOCALE_PUS: 'pachto',
    COMMON_LANGUAGES_LOCALE_QAA: 'réservée à l\'usage local',
    COMMON_LANGUAGES_LOCALE_QAA_QTZ: 'réservée à l\'usage local',
    COMMON_LANGUAGES_LOCALE_QUE: 'quechua',
    COMMON_LANGUAGES_LOCALE_RAJ: 'rajasthani',
    COMMON_LANGUAGES_LOCALE_RAP: 'rapanui',
    COMMON_LANGUAGES_LOCALE_RAR: 'rarotonga',
    COMMON_LANGUAGES_LOCALE_ROA: 'romanes, langues',
    COMMON_LANGUAGES_LOCALE_ROH: 'romanche',
    COMMON_LANGUAGES_LOCALE_ROM: 'tsigane',
    COMMON_LANGUAGES_LOCALE_RUM_RON: 'roumain',
    COMMON_LANGUAGES_LOCALE_RUN: 'rundi',
    COMMON_LANGUAGES_LOCALE_RUP: 'macédo-roumain',
    COMMON_LANGUAGES_LOCALE_RUS: 'russe',
    COMMON_LANGUAGES_LOCALE_SAD: 'sandawe',
    COMMON_LANGUAGES_LOCALE_SAG: 'sango',
    COMMON_LANGUAGES_LOCALE_SAH: 'iakoute',
    COMMON_LANGUAGES_LOCALE_SAI: 'sud-amérindiennes, langues',
    COMMON_LANGUAGES_LOCALE_SAL: 'salishennes, langues',
    COMMON_LANGUAGES_LOCALE_SAM: 'samaritain',
    COMMON_LANGUAGES_LOCALE_SAN: 'sanskrit',
    COMMON_LANGUAGES_LOCALE_SAS: 'sasak',
    COMMON_LANGUAGES_LOCALE_SAT: 'santal',
    COMMON_LANGUAGES_LOCALE_SCN: 'sicilien',
    COMMON_LANGUAGES_LOCALE_SCO: 'écossais',
    COMMON_LANGUAGES_LOCALE_SEL: 'selkoupe',
    COMMON_LANGUAGES_LOCALE_SEM: 'sémitiques, langues',
    COMMON_LANGUAGES_LOCALE_SGA: 'irlandais ancien (jusqu\'à 900)',
    COMMON_LANGUAGES_LOCALE_SGN: 'langues des signes',
    COMMON_LANGUAGES_LOCALE_SHN: 'chan',
    COMMON_LANGUAGES_LOCALE_SID: 'sidamo',
    COMMON_LANGUAGES_LOCALE_SIN: 'singhalais',
    COMMON_LANGUAGES_LOCALE_SIO: 'sioux, langues',
    COMMON_LANGUAGES_LOCALE_SIT: 'sino-tibétaines, langues',
    COMMON_LANGUAGES_LOCALE_SLA: 'slaves, langues',
    COMMON_LANGUAGES_LOCALE_SLO_SLK: 'slovaque',
    COMMON_LANGUAGES_LOCALE_SLV: 'slovène',
    COMMON_LANGUAGES_LOCALE_SMA: 'sami du sud',
    COMMON_LANGUAGES_LOCALE_SME: 'sami du nord',
    COMMON_LANGUAGES_LOCALE_SMI: 'sames, langues',
    COMMON_LANGUAGES_LOCALE_SMJ: 'sami de Lule',
    COMMON_LANGUAGES_LOCALE_SMN: 'sami d\'Inari',
    COMMON_LANGUAGES_LOCALE_SMO: 'samoan',
    COMMON_LANGUAGES_LOCALE_SMS: 'sami skolt',
    COMMON_LANGUAGES_LOCALE_SNA: 'shona',
    COMMON_LANGUAGES_LOCALE_SND: 'sindhi',
    COMMON_LANGUAGES_LOCALE_SNK: 'soninké',
    COMMON_LANGUAGES_LOCALE_SOG: 'sogdien',
    COMMON_LANGUAGES_LOCALE_SOM: 'somali',
    COMMON_LANGUAGES_LOCALE_SON: 'songhai, langues',
    COMMON_LANGUAGES_LOCALE_SOT: 'sotho du sud',
    COMMON_LANGUAGES_LOCALE_SP: 'espagnol',
    COMMON_LANGUAGES_LOCALE_SPA: 'espagnol',
    COMMON_LANGUAGES_LOCALE_SRD: 'sarde',
    COMMON_LANGUAGES_LOCALE_SRN: 'sranan tongo',
    COMMON_LANGUAGES_LOCALE_SRP: 'serbe',
    COMMON_LANGUAGES_LOCALE_SRR: 'sérère',
    COMMON_LANGUAGES_LOCALE_SSA: 'nilo-sahariennes, langues',
    COMMON_LANGUAGES_LOCALE_SSW: 'swati',
    COMMON_LANGUAGES_LOCALE_SUK: 'sukuma',
    COMMON_LANGUAGES_LOCALE_SUN: 'soundanais',
    COMMON_LANGUAGES_LOCALE_SUS: 'soussou',
    COMMON_LANGUAGES_LOCALE_SUX: 'sumérien',
    COMMON_LANGUAGES_LOCALE_SWA: 'swahili',
    COMMON_LANGUAGES_LOCALE_SWE: 'suédois',
    COMMON_LANGUAGES_LOCALE_SYC: 'syriaque classique',
    COMMON_LANGUAGES_LOCALE_SYR: 'syriaque',
    COMMON_LANGUAGES_LOCALE_TAH: 'tahitien',
    COMMON_LANGUAGES_LOCALE_TAI: 'tai, langues',
    COMMON_LANGUAGES_LOCALE_TAM: 'tamoul',
    COMMON_LANGUAGES_LOCALE_TAT: 'tatar',
    COMMON_LANGUAGES_LOCALE_TEL: 'télougou',
    COMMON_LANGUAGES_LOCALE_TEM: 'temne',
    COMMON_LANGUAGES_LOCALE_TER: 'tereno',
    COMMON_LANGUAGES_LOCALE_TET: 'tetum',
    COMMON_LANGUAGES_LOCALE_TGK: 'tadjik',
    COMMON_LANGUAGES_LOCALE_TGL: 'tagalog',
    COMMON_LANGUAGES_LOCALE_THA: 'thaï',
    COMMON_LANGUAGES_LOCALE_TIB_BOD: 'tibétain',
    COMMON_LANGUAGES_LOCALE_TIG: 'tigré',
    COMMON_LANGUAGES_LOCALE_TIR: 'tigrigna',
    COMMON_LANGUAGES_LOCALE_TIV: 'tiv',
    COMMON_LANGUAGES_LOCALE_TKL: 'tokelau',
    COMMON_LANGUAGES_LOCALE_TLH: 'klingon',
    COMMON_LANGUAGES_LOCALE_TLI: 'tlingit',
    COMMON_LANGUAGES_LOCALE_TMH: 'tamacheq',
    COMMON_LANGUAGES_LOCALE_TOG: 'tonga (Nyasa)',
    COMMON_LANGUAGES_LOCALE_TON: 'tongan (Îles Tonga)',
    COMMON_LANGUAGES_LOCALE_TPI: 'tok pisin',
    COMMON_LANGUAGES_LOCALE_TSI: 'tsimshian',
    COMMON_LANGUAGES_LOCALE_TSN: 'tswana',
    COMMON_LANGUAGES_LOCALE_TSO: 'tsonga',
    COMMON_LANGUAGES_LOCALE_TUK: 'turkmène',
    COMMON_LANGUAGES_LOCALE_TUM: 'tumbuka',
    COMMON_LANGUAGES_LOCALE_TUP: 'tupi, langues',
    COMMON_LANGUAGES_LOCALE_TUR: 'turc',
    COMMON_LANGUAGES_LOCALE_TUT: 'altaïques, langues',
    COMMON_LANGUAGES_LOCALE_TVL: 'tuvalu',
    COMMON_LANGUAGES_LOCALE_TWI: 'twi',
    COMMON_LANGUAGES_LOCALE_TYV: 'touva',
    COMMON_LANGUAGES_LOCALE_UDM: 'oudmourte',
    COMMON_LANGUAGES_LOCALE_UGA: 'ougaritique',
    COMMON_LANGUAGES_LOCALE_UIG: 'ouïgour',
    COMMON_LANGUAGES_LOCALE_UKR: 'ukrainien',
    COMMON_LANGUAGES_LOCALE_UMB: 'umbundu',
    COMMON_LANGUAGES_LOCALE_UND: 'indéterminée',
    COMMON_LANGUAGES_LOCALE_URD: 'ourdou',
    COMMON_LANGUAGES_LOCALE_UZB: 'ouszbek',
    COMMON_LANGUAGES_LOCALE_VAI: 'vaï',
    COMMON_LANGUAGES_LOCALE_VEN: 'venda',
    COMMON_LANGUAGES_LOCALE_VIE: 'vietnamien',
    COMMON_LANGUAGES_LOCALE_VOL: 'volapük',
    COMMON_LANGUAGES_LOCALE_VOT: 'vote',
    COMMON_LANGUAGES_LOCALE_WAK: 'wakashanes, langues',
    COMMON_LANGUAGES_LOCALE_WAL: 'wolaytta',
    COMMON_LANGUAGES_LOCALE_WAR: 'waray',
    COMMON_LANGUAGES_LOCALE_WAS: 'washo',
    COMMON_LANGUAGES_LOCALE_WEL_CYM: 'gallois',
    COMMON_LANGUAGES_LOCALE_WEN: 'sorabes, langues',
    COMMON_LANGUAGES_LOCALE_WLN: 'wallon',
    COMMON_LANGUAGES_LOCALE_WOL: 'wolof',
    COMMON_LANGUAGES_LOCALE_XAL: 'oïrat',
    COMMON_LANGUAGES_LOCALE_XHO: 'xhosa',
    COMMON_LANGUAGES_LOCALE_YAO: 'yao',
    COMMON_LANGUAGES_LOCALE_YAP: 'yapois',
    COMMON_LANGUAGES_LOCALE_YID: 'yiddish',
    COMMON_LANGUAGES_LOCALE_YOR: 'yoruba',
    COMMON_LANGUAGES_LOCALE_YPK: 'yupik, langues',
    COMMON_LANGUAGES_LOCALE_ZAP: 'zapotèque',
    COMMON_LANGUAGES_LOCALE_ZBL: 'symboles bliss',
    COMMON_LANGUAGES_LOCALE_ZEN: 'zenaga',
    COMMON_LANGUAGES_LOCALE_ZHA: 'zhuang',
    COMMON_LANGUAGES_LOCALE_ZND: 'zandé, langues',
    COMMON_LANGUAGES_LOCALE_ZUL: 'zoulou',
    COMMON_LANGUAGES_LOCALE_ZUN: 'zuni',
    COMMON_LANGUAGES_LOCALE_ZXX: 'pas de contenu linguistique',
    COMMON_LANGUAGES_LOCALE_ZZA: 'zazaki'
  },
  messages: {
    MEDIA_KEYSYSERR_ACCESS_DENIED_TITLE: 'Problème de configuration (code 5140)',
    MEDIA_KEYSYSERR_ACCESS_DENIED_CONTENT: '<p>Merci de vérifier la configuration de votre navigateur.</p>',

    DOWNLOAD_ERR_MANIFEST_TITLE: 'Une erreur est survenue (code 5050)',
    DOWNLOAD_ERR_MANIFEST_CONTENT: '<p>Merci d\'essayer à nouveau. <br />Si le problème persiste, veuillez contacter le support <br />via le lien \'nous contacter\' en bas de page.</p>',

    MEDIA_ERR_ABORTED_TITLE: 'Une erreur est survenue (code 5020)',
    MEDIA_ERR_ABORTED_CONTENT: '<p>Merci d\'essayer à nouveau. <br />Si le problème persiste, veuillez contacter le support <br />via le lien \'nous contacter\' en bas de page.</p>',

    MEDIA_ERR_NETWORK_TITLE: 'Une erreur est survenue (code 5021)',
    MEDIA_ERR_NETWORK_CONTENT: '<p>Merci d\'essayer à nouveau. <br />Si le problème persiste, veuillez contacter le support <br />via le lien \'nous contacter\' en bas de page.</p>',

    MEDIA_ERR_DECODE_TITLE: 'Une erreur est survenue (code 5022)',
    MEDIA_ERR_DECODE_CONTENT: '<p>Merci d\'essayer à nouveau. <br />Si le problème persiste, veuillez contacter le support <br />via le lien \'nous contacter\' en bas de page.</p>',

    MEDIA_ERR_SRC_NOT_SUPPORTED_TITLE: 'Problème de configuration (code 5023)',
    MEDIA_ERR_SRC_NOT_SUPPORTED_CONTENT: '<p>La configuration de votre navigateur ne permet pas de visionner votre vidéo.</p>',

    MEDIA_ERR_REMOVE_SOURCEBUFFER_TITLE: 'Une erreur est survenue',
    MEDIA_ERR_REMOVE_SOURCEBUFFER_CONTENT: '<p>Nous vous conseillons d\'actualiser la page. <br />Si le problème persiste, veuillez contacter le support <br />via le lien \'nous contacter\' en bas de page.</p>. <br />Code erreur 5024</p>',

    MEDIA_ERR_CREATE_SOURCEBUFFER_TITLE: 'Une erreur est survenue (code 5025)',
    MEDIA_ERR_CREATE_SOURCEBUFFER_CONTENT: '<p>Nous vous conseillons d\'actualiser la page. <br />Si le problème persiste, veuillez contacter le support <br />via le lien \'nous contacter\' en bas de page.</p>',

    MEDIA_ERR_APPEND_SOURCEBUFFER_TITLE: 'Une erreur est survenue (code 5026)',
    MEDIA_ERR_APPEND_SOURCEBUFFER_CONTENT: '<p>Nous vous conseillons d\'actualiser la page. <br />Si le problème persiste, veuillez contacter le support <br />via le lien \'nous contacter\' en bas de page.</p>',

    'MEDIA_ERR_CODEC_UNSUPPORTED _TITLE': 'Problème de configuration (code 5030)',
    'MEDIA_ERR_CODEC_UNSUPPORTED _CONTENT': '<pLa configuration de votre navigateur ne permet pas de visionner votre vidéo.</p>',

    MANIFEST_ERR_PARSE_TITLE: 'Une erreur est survenue (code 5031)',
    MANIFEST_ERR_PARSE_CONTENT: '<p>Merci d\'essayer à nouveau. <br />Si le problème persiste, veuillez contacter le support <br />via le lien \'nous contacter\' en bas de page.</p>',

    MANIFEST_ERR_NO_STREAM_TITLE: 'Une erreur est survenue (code 5032)',
    MANIFEST_ERR_NO_STREAM_CONTENT: '<p>Merci d\'essayer à nouveau. <br />Si le problème persiste, veuillez contacter le support <br />via le lien \'nous contacter\' en bas de page.</p>',

    MANIFEST_ERR_NO_VIDEO_TITLE: 'Une erreur est survenue (code 5033)',
    MANIFEST_ERR_NO_VIDEO_CONTENT: '<p>Merci d\'essayer à nouveau. <br />Si le problème persiste, veuillez contacter le support <br />via le lien \'nous contacter\' en bas de page.</p>',

    CAPABILITY_ERR_MEDIASOURCE_TITLE: 'Configuration non compatible (code 5040)',
    CAPABILITY_ERR_MEDIASOURCE_CONTENT: '<p>Merci d’activer les extensions vidéo pour regarder les vidéos sur votre ordinateur.</p>',

    MEDIA_ERR_CREATE_MEDIASOURCE_TITLE: 'Problème de configuration (code 5041)',
    MEDIA_ERR_CREATE_MEDIASOURCE_CONTENT: '<p>Un problème système empêche la lecture des flux vidéo.<b />Nous vous conseillons d\'essayer avec un autre navigateur.</p>',

    DOWNLOAD_ERR_CONTENT_TITLE: 'Problème de débit Internet (code 5051)',
    DOWNLOAD_ERR_CONTENT_CONTENT: '<p>Nous vous invitons à effectuer un test de débit via le lien tester ma configuration en bas de page, et à vérifier les usages simultanés sur votre ligne.</p>',

    MEDIA_ERR_ENCRYPTED_TITLE: 'Une erreur est survenue (code 5110)',
    MEDIA_ERR_ENCRYPTED_CONTENT: '<p>Merci d\'essayer à nouveau. <br />Si le problème persiste, veuillez contacter le support <br />via le lien \'nous contacter\' en bas de page.</p>',

    CAPABILITY_ERR_MEDIAKEYS_TITLE: 'Configuration non compatible (code 5120)',
    CAPABILITY_ERR_MEDIAKEYS_CONTENT: '<p>La configuration de votre navigateur ne permet pas de visionner votre vidéo.</p>',

    MEDIA_KEYERR_TITLE: 'Une erreur est survenue (code 5130)',
    MEDIA_KEYERR_CONTENT: '<p>Merci d\'essayer à nouveau. <br />Si le problème persiste, veuillez contacter le support <br />via le lien \'nous contacter\' en bas de page.</p>',

    MEDIA_KEYERR_UNKNOWN_TITLE: 'Une erreur est survenue (code 5131)',
    MEDIA_KEYERR_UNKNOWN_CONTENT: '<p>Merci d\'essayer à nouveau. <br />Si le problème persiste, veuillez contacter le support <br />via le lien \'nous contacter\' en bas de page.</p>',

    MEDIA_KEYERR_EXPIRED_TITLE: 'Une erreur est survenue (code 5111)',
    MEDIA_KEYERR_EXPIRED_CONTENT: '<p>Merci d\'essayer à nouveau.</p>',

    MEDIA_KEYERR_CLIENT_TITLE: 'Problème de configuration (code 5132)',
    MEDIA_KEYERR_CLIENT_CONTENT: '<p>Merci de vérifier la configuration de votre navigateur.</p>',

    MEDIA_KEYERR_SERVICE_TITLE: 'Une erreur est survenue (code 5133)',
    MEDIA_KEYERR_SERVICE_CONTENT: '<p>Merci d\'essayer à nouveau. <br />Si le problème persiste, veuillez contacter le support <br />via le lien \'nous contacter\' en bas de page.</p>',

    MEDIA_KEYERR_OUTPUT_TITLE: 'Impossible de lire la vidéo demandée (code 5134)',
    MEDIA_KEYERR_OUTPUT_CONTENT: '<p>Votre configuration ne permet pas de lire certaines vidéos protégées.<b /> Nous vous invitons à changer de navigateur ou à déconnecter vos écrans externes.</p>',

    MEDIA_KEYERR_HARDWARECHANGE_TITLE: 'Problème de configuration matérielle (code 5135)',
    MEDIA_KEYERR_HARDWARECHANGE_CONTENT: '<p>Un changement dans la configuration de votre ordinateur bloque la lecture de votre vidéo.</p>',

    MEDIA_KEYERR_DOMAIN_TITLE: 'Une erreur est survenue (code 5136)',
    MEDIA_KEYERR_DOMAIN_CONTENT: '<p>Merci d\'essayer à nouveau. <br />Si le problème persiste, veuillez contacter le support <br />via le lien \'nous contacter\' en bas de page.</p>',

    MEDIA_KEYMESSERR_LICENSER_UNKNOWN_TITLE: 'Une erreur est survenue (code 5150)',
    MEDIA_KEYMESSERR_LICENSER_UNKNOWN_CONTENT: '<p>Merci d\'essayer à nouveau. <br />Si le problème persiste, veuillez contacter le support <br />via le lien \'nous contacter\' en bas de page.</p>',

    MEDIA_KEYMESSERR_NO_CHALLENGE_TITLE: 'Une erreur est survenue (code 5151)',
    MEDIA_KEYMESSERR_NO_CHALLENGE_CONTENT: '<p>Merci d\'essayer à nouveau. <br />Si le problème persiste, veuillez contacter le support <br />via le lien \'nous contacter\' en bas de page.</p>',

    MEDIA_KEYMESSERR_NO_SESSION_TITLE: 'Une erreur est survenue (code 5154)',
    MEDIA_KEYMESSERR_NO_SESSION_CONTENT: '<p>Nous vous conseillons d\'actualiser la page.<br />Si le problème persiste, veuillez contacter le support <br />via le lien \'nous contacter\' en bas de page.</p>',

    MEDIA_ERR_CODEC_UNSUPPORTED_TITLE: 'Problème de configuration (code 5030)',
    MEDIA_ERR_CODEC_UNSUPPORTED_CONTENT: '<p>La configuration de votre navigateur ne permet pas de visionner votre vidéo.</p>',

    BROWSER_UNCOMPATIBLE_TITLE: 'Configuration non compatible',
    BROWSER_UNCOMPATIBLE_CONTENT: 'Votre navigateur ne permet pas de lire les vidéos.<br />  <a href="https://r.orange.fr/r/Owebtv_replay_help">Nous vous invitons à consulter l’assistance</a>.',

    MEDIA_KEYMESSERR_LICENSER_ERROR_TITLE: 'Une erreur est survenue (code 5153)',
    MEDIA_KEYMESSERR_LICENSER_ERROR_CONTENT: '<p>Merci d\'essayer à nouveau. <br />Si le problème persiste, veuillez contacter le support <br />via le lien \'nous contacter\' en bas de page.</p>',

    DEFAULT_PLAYER_ERROR: {
      title: 'Erreur de lecture (code : {errorCode})',
      content: 'Une erreur est survenue : {description} <br/> Merci d\'essayer à nouveau.<br/> Si le problème persiste, veuillez contacter le support via le lien "nous contacter" en bas de page.'
    }
  }
}
