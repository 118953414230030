import Vue from 'vue'
import Router from 'vue-router'
import scrollBehavior from './router.scrollBehavior.js'
import Navik from '@wptv/navik'

const Home = () => import(/* webpackChunkName: "home" */ './components/home/Home.vue').then(m => m.default || m)
const Genre = () => import(/* webpackChunkName: "genre" */ './components/genre/Genre.vue').then(m => m.default || m)
const Corner = () => import(/* webpackChunkName: "corner" */ './components/corner/CornerChannel.vue').then(m => m.default || m)
const Rubric = () => import(/* webpackChunkName: "rubrique" */ './components/rubric/RubricChannel.vue').then(m => m.default || m)
const FipVideo = () => import(/* webpackChunkName: "fipvideo" */ './components/fip/FipVideo').then(m => m.default || m)
const FipGroup = () => import(/* webpackChunkName: "fipgroup" */ './components/fip/FipGroup.vue').then(m => m.default || m)

Vue.use(Router)

export function createRouter () {
  return new Router({
    mode: 'history',
    routes: [
      {
        name: 'Home',
        path: '/',
        component: Home,
        meta: {
          navik: {
            nodeType: Navik.CONSTANT.NODE_TYPE.ROOT,
            hasBackLink: false
          }
        }
      },
      {
        name: 'Genre',
        path: '/genres/:genreId',
        component: Genre,
        props: true,
        meta: {
          navik: {
            nodeType: Navik.CONSTANT.NODE_TYPE.NODE,
            hasBackLink: false
          }
        }
      },
      {
        name: 'Corner',
        path: '/channels/:channelId',
        component: Corner,
        props: true,
        meta: {
          navik: {
            nodeType: Navik.CONSTANT.NODE_TYPE.NODE,
            hasBackLink: true
          }
        }
      },
      {
        name: 'Rubric',
        path: '/channels/:channelId/categories/:categoryId',
        component: Rubric,
        props: true,
        meta: {
          navik: {
            nodeType: Navik.CONSTANT.NODE_TYPE.LEAF,
            hasBackLink: false
          }
        }
      },
      {
        name: 'FipVideo',
        path: '/videos/:videoId',
        component: FipVideo,
        props: true,
        meta: {
          navik: {
            nodeType: Navik.CONSTANT.NODE_TYPE.LEAF,
            hasBackLink: true
          }
        }
      },
      {
        name: 'FipGroup',
        path: '/groups/:groupId/videos/:videoId?',
        component: FipGroup,
        props: true,
        meta: {
          navik: {
            nodeType: Navik.CONSTANT.NODE_TYPE.LEAF,
            hasBackLink: true
          }
        }
      }
    ],
    scrollBehavior
  })
}
