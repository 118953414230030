<template>
  <div id="app">
    <Nuxt />
  </div>
</template>
<script>
import commonElements from '../components/shared/commonElements/commonElements.mixin'

export default {
  mixins: [
    commonElements
  ]
}

</script>
