import { defineStore } from 'pinia'
import { useUserAgentStore } from '../useragent/userAgentStore'
import { useUserStore } from '../user/userStore'
import { useErrorStore } from '../errors/errorStore'

export const useVideoStore = defineStore('video', {
  state: () => ({
    playingInfo: {},
    safariCertificate: '',
    chromeCertificate: '',
    video: {}
  }),
  actions: {
    async fetchPlayingInfo (videoId: string) {
      const terminalModel: string = useUserAgentStore().getTerminalModel
      const terminalId: string = useUserStore().uniqueId

      return await this.$nuxt.app.$api.tvm.videos.getPlayingInfo({ videoId, terminalId, terminalModel })
        .then((playingInfo) => {
          this.playingInfo = playingInfo
        })
        .catch(error => useErrorStore().setAxiosError(error))
    },

    async fetchVideo (videoId: string) {
      return await this.$nuxt.app.$api.tvm.videos.getVideo(videoId)
        .then((video) => {
          this.video = video
        })
        .catch(error => useErrorStore().setAxiosError(error))
    },

    async fetchCertificateSafari () {
      return await this.$nuxt.app.$api.external.certificate.getCertificate(true)
        .then((certificate) => {
          const byteArray = new Uint8Array(certificate)
          const string = String.fromCharCode.apply(null, byteArray)

          this.safariCertificate = btoa(string)
        })
        .catch(error => useErrorStore().setAxiosError(error))
    },

    async fetchCertificateChrome () {
      return await this.$nuxt.app.$api.external.certificate.getCertificate(false)
        .then((certificate) => {
          const byteArray = new Uint8Array(certificate)
          const string = String.fromCharCode.apply(null, byteArray)

          this.chromeCertificate = btoa(string)
        })
        .catch(error => useErrorStore().setAxiosError(error))
    }
  }
})
