import { profileHasAccess } from '../middleware/checkups'
import { useChannelStore } from '../store/channels/channelStore'
import { useNpvrStore } from '../store/npvr/npvrStore'
import { useUserStore } from '../store/user/userStore'
import { useVideoStore } from '../store/videos/videoStore'

/**
 * Init store on client side only
 * @param store
 * @returns {Promise<{-readonly [P in keyof *[]]: PromiseSettledResult<*[][P] extends PromiseLike<infer U> ? U : *[][P]>}>}
 */
export default ({ store, $pinia }) => {
  useChannelStore($pinia).fetchLiveChannels(useNpvrStore($pinia).user.customerOrangePopulation)

  if (profileHasAccess(store.getters['core/getProfile'])) {
    return Promise.allSettled([
      useUserStore($pinia).fetchTimeOffset(),
      useVideoStore($pinia).fetchCertificateSafari(),
      useVideoStore($pinia).fetchCertificateChrome()
    ])
  }
}
