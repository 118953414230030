export default {
  ERRORS_CODES: {
    NOT_AUTHENTICATED: ['41', '40'],
    TOKEN_EXPIRED: ['42'],
    MAX_SIMULTANEOUS_VIEWS_REACHED: ['2402'],
    MAX_TERMINALS_EXCEEDED: ['2403'],
    POPIN_NOT_HLS_MAC_ERROR: ['2404'],
    REPLAY_DATA_NOT_FOUND: ['60'],
    BROWSER_UNCOMPATIBLE: ['BROWSER_UNCOMPATIBLE'],
    NOT_BOUQUET_SOUSCRIBE_NOSOSH: ['NOT_BOUQUET_SOUSCRIBE_NOSOSH'],
    NOT_BOUQUET_SOUSCRIBE_SOSH: ['NOT_BOUQUET_SOUSCRIBE_SOSH'],
    NOMADISM_NOT_ALLOWED: ['NOMADISM_NOT_ALLOWED'],
    MOBILE_ERROR: ['MOBILE_ERROR']
  },
  PAGES: {
    MOSAIQUE: 'MOSAIQUE',
    PLAYER_WRAPPER: 'PLAYER_WRAPPER'
  },
  QUALITIES: {
    HD: 'HD',
    SD: 'SD'
  },
  LANGUAGES: {
    VOST_VF: 'VOST/VF',
    VF: 'VF',
    VM: 'VM',
    VO: 'VO',
    VOST: 'VOST',
    HD: 'HD',
    QAD: 'QAD',
    HI: 'HI'
  },
  DEFAULT_QUALITY: 85,
  OLD_API_URL: 'p/',
  REST_API_URL: 'images/',
  FORMATS: {
    MOSAIQUE: {
      RATIO_16_9: [
        {
          media: '(max-width:1200px)',
          format: '272x'
        },
        {
          media: '(max-width:1400px)',
          format: '300x'
        },
        {
          media: '(min-width:1401px)',
          format: '400x'
        }
      ]
    },
    PLAYER_WRAPPER: {
      RATIO_16_9: [
        {
          media: '(max-width:320px)',
          format: '283x159'
        },
        {
          media: '(max-width:480px)',
          format: '384x216'
        },
        {
          media: '(max-width:736px)',
          format: '558x314'
        },
        {
          media: '(max-width:960px)',
          format: '800x450'
        },
        {
          media: '(max-width:1200px)',
          format: '384x216'
        },
        {
          media: '(min-width:1201px)',
          format: '558x314'
        }
      ]
    }
  },
  ORIGIN: 'REPLAY',
  ERROR_TOKEN: {
    PREFIX: 'TOKEN_',
    TOKEN_TIMEOUT: 'TIMEOUT',
    ECONNABORTED: 'ECONNABORTED'
  },
  SUSPENDED_CODES: {
    NA: 'NA',
    SUSPENDED: 'SUSPENDED',
    SUSPENDED_GUEST: 'SUSPENDED_GUEST',
    INVALID: 'HOMECHECKSTATUS_INVALID',
    NOT_FOUND: 'OPTION_NOT_FOUND ',
    NOT_EXISTING: 'NOT_EXISTING'
  }
}
