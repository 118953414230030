<template>
  <div>
    <desktop-menu
      :menu="genreStore.genres"
      :sticked="sticked"
      class="show-from-small" />
    <mobile-menu :menu="genreStore.genres" class="hide-from-small" />
  </div>
</template>

<script>
import DesktopMenu from './desktopMenu/DesktopMenu'
import MobileMenu from './mobileMenu/MobileMenu'
import { mapStores } from 'pinia'
import { useGenreStore } from '../../../store/genres/genreStore'

export default {
  name: 'Menu',
  components: {
    MobileMenu,
    DesktopMenu
  },
  data () {
    return {
      sticked: false
    }
  },
  computed: {
    ...mapStores(useGenreStore)
  },
  methods: {
    menuStick (sticked = false) {
      this.sticked = sticked
    }
  }
}
</script>
<style lang="scss" scoped>
  .menu {
    max-width: 90rem;
    background-color: $white;
    z-index: 98;
  }
</style>
