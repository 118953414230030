function onClickDefaultSort (page) {
  return {
    track_page: page,
    track_zone: 'mosaique',
    track_nom: 'ordre_defaut',
    track_type: 'clic',
    track_cible: '',
    track_detail: `clic-ordre_defaut-mosaique-${page}`
  }
}

function onClickAlphaSort (page) {
  return {
    track_page: page,
    track_zone: 'mosaique',
    track_nom: 'ordre_alpha',
    track_type: 'clic',
    track_cible: '',
    track_detail: `clic-ordre_alpha-mosaique-${page}`
  }
}

function onClickChannelSort (page) {
  return {
    track_page: page,
    track_zone: 'mosaique',
    track_nom: 'ordre_chaine',
    track_type: 'clic',
    track_cible: '',
    track_detail: `clic-ordre_chaine-mosaique-${page}`
  }
}

export default {
  onClickAlphaSort,
  onClickDefaultSort,
  onClickChannelSort
}
