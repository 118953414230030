import urlJoin from 'url-join'
import _get from 'lodash/get'

export default {
  /**
   * Retrieve channels
   * @returns {Promise<* | never>}
   */
  getChannels () {
    const method = 'api.channels.getChannels()'
    const options = {
      params: { mco: this.getMCO() },
      cache: {
        exclude: { query: false }
      }
    }
    const path = urlJoin(
      'applications',
      this.getApplication(),
      _get(this.$config, 'confetti.public.api.path.channels'))

    return this.$http.catchup.notAuth.get(path, options)
      .then(response => this.$httpUtils.parseResponse(response, true))
      .catch(responseError => this.$httpUtils.catchError({ responseError, method }))
  },
  /**
   * Get a specific channel by id
   * @param channelId
   * @returns {Promise<* | never>}
   */
  getChannel (channelId) {
    const method = 'api.channels.getChannel()'
    const options = {
      params: { mco: this.getMCO() },
      cache: {
        exclude: { query: false }
      }
    }
    const path = urlJoin(
      'applications',
      this.getApplication(),
      _get(this.$config, 'confetti.public.api.path.channels'),
      channelId
    )

    return this.$http.catchup.notAuth.get(path, options)
      .then(response => this.$httpUtils.parseResponse(response, false))
      .catch(responseError => this.$httpUtils.catchError({ responseError, method }))
  },
  /**
   * Get a specific channel category by id
   * @param channelId
   * @param categoryId
   * @param mco
   * @returns {Promise<* | never>}
   */
  getChannelCategory (channelId, categoryId) {
    const method = 'api.channels.getChannelCategory()'
    const options = {
      params: { mco: this.getMCO() },
      cache: {
        exclude: { query: false }
      }
    }
    const path = urlJoin(
      'applications',
      this.getApplication(),
      _get(this.$config, 'confetti.public.api.path.channels'),
      channelId,
      _get(this.$config, 'confetti.public.api.path.categories'),
      categoryId
    )

    return this.$http.catchup.notAuth.get(path, options)
      .then(response => this.$httpUtils.parseResponse(response, false))
      .catch(responseError => this.$httpUtils.catchError({ responseError, method }))
  },
  /**
   * Get Live channels
   * @returns {Promise<* | never>}
   */
  getLiveChannels (everywherePopulation = 'OTT_Metro') {
    const method = 'api.channels.getLiveChannels()'
    const path = urlJoin(_get(this.$config, 'confetti.public.api.path.liveChannels'))

    const options = {
      params: {
        everywherePopulation
      },
      cache: {
        exclude: { query: false }
      }
    }

    return this.$http.gateway.pds.notAuth.get(path, options)
      .then(response => this.$httpUtils.parseResponse(response, false))
      .catch(responseError => this.$httpUtils.catchError({ responseError, method, bypass: true }))
  }
}
