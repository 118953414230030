import _get from 'lodash/get'
import _has from 'lodash/has'
import { ONEPLAYERUI_CONFIG } from '@wptv/player/src/shared/config/player.config'
import { ONEPLAYERUI_ERRORS } from '@wptv/player/src/shared/errors/player.errors'
import API_CONFIG from '../../../components/shared/api.config'

/**
 * Generate a axios error object
 * @param {Object} axiosError - axios error info
 * @param {pageName: String, fullPath: String} pageInfo - page info
 * @returns {Object} - a formated error
 *            {status: Number,
 *            statusText: String,
 *            url: String,
 *            method: ['get'|'post'],
 *            tvmCode: String,
 *            code: String,
 *            type: String,
 *            origin: String,
 *            tvmMessage: String,
 *            tvmDescription: String,
 *            pageName: String,
 *            fullPath: String,
 *            contentType: String}}
 */
export function formatAxiosError (axiosError, { pageName = '', fullPath = '', nbInSession, portalVersion = '' }) {
  return {
    nbInSession,
    status: _get(axiosError, 'error.status', -1),
    statusText: _get(axiosError, 'error.statusText', '-'),
    url: _get(axiosError, 'config.url', 'no url'),
    method: _get(axiosError, 'config.method', 'no method'),
    apiCode: String(_get(axiosError, 'error.data.code', '-')),
    errorCode: String(_get(axiosError, 'error.data.code', '-')),
    channelId: _get(axiosError, 'channelId', 'no channel id'),
    channelName: _get(axiosError, 'channelName', 'no channel name'),
    contentId: _get(axiosError, 'contentId', 'no content id'),
    contentTitle: _get(axiosError, 'contentTitle', 'no content title'),
    errorMessage: _get(axiosError, 'message', 'no error message'),
    type: 'API',
    origin: API_CONFIG.ORIGIN,
    apiMessage: _get(axiosError, 'error.data.message', 'no api message'),
    apiDescription: _get(axiosError, 'error.data.description', 'Problème réseau'),
    pageName,
    fullPath,
    portalVersion,
    contentType: pageName
  }
}
/**
 * Generate a player error object
 * @param {Object} playerError - player error info
 * @param {pageName: String, fullPath: String} pageInfo - page info
 * @returns {Object} - a formated error
 *            url: String,
 *            code: String,
 *            message: String,
 *            type: String,
 *            origin: String,
 *            pageName: String,
 *            fullPath: String,
 *            contentType: String}}
 */
export function formatPlayerError (playerError, { pageName = '', fullPath = '', nbInSession, portalVersion = '' }) {
  // Deux format d'erreur différent d'ou le double _get
  // - La premiere pour l'erreur sur stream
  // - L'autre pour les erreurs de manifest, fragment, ...
  let errorCode = _get(playerError, 'error.data.code', _get(playerError, 'code', ONEPLAYERUI_CONFIG.DEFAULT_ERROR))

  if (errorCode !== ONEPLAYERUI_CONFIG.DEFAULT_ERROR) {
    if (_has(playerError, 'error.data.code')) {
      errorCode = getNameFromApiErrorCode(errorCode)
    } else {
      errorCode = getNameFromStreamErrorCode(errorCode)
    }
  }

  // Add 'WARNING:' prefix to the error codes 20 and 21 when they are marked as warning (WEBTV-4484)
  if (playerError.isWarning) {
    errorCode = `WARNING:${errorCode}`
  }

  return {
    nbInSession,
    url: String(_get(playerError, 'config.path', _get(playerError, 'data.url', _get(playerError, 'data.uri', 'no url')))),
    errorCode,
    data: JSON.stringify(_get(playerError, 'data', 'no data')),
    message: String(_get(playerError, 'error.data.message', _get(playerError, 'message', 'no player error code'))),
    status: String(_get(playerError, 'error.status', _get(playerError, 'data.status', 'no status'))),
    type: 'PLAYER',
    origin: API_CONFIG.ORIGIN,
    pageName,
    fullPath,
    portalVersion,
    contentType: pageName
  }
}

export function formatGenericError (genericError, { pageName = '', fullPath = '', nbInSession, portalVersion = '' }) {
  return {
    ...genericError,
    nbInSession,
    type: 'GENERIC',
    origin: API_CONFIG.ORIGIN,
    pageName,
    fullPath,
    portalVersion,
    contentType: pageName
  }
}

/**
 * Get the name of the stream error code
 * @param code
 * @returns {string}
 */
export function getNameFromStreamErrorCode (code) {
  const errorEntry = Object.entries(ONEPLAYERUI_ERRORS).find(item => item[1] === code)
  return errorEntry ? errorEntry[0] : `${ONEPLAYERUI_CONFIG.DEFAULT_NO_CODE_FOUND_ERROR}: ${code}`
}

/**
 * Get the name of the API error code (TVM or Gateway)
 * @param code
 * @returns {string}
 */
export function getNameFromApiErrorCode (code) {
  let errorEntry = Object.entries(API_CONFIG.ERRORS_CODES).find(category => category[1].find(errorCode => errorCode === `${code}`))

  if (!errorEntry) {
    errorEntry = Object.entries(API_CONFIG.GATEWAY_ERRORS_CODES).find(category => category[1].find(errorCode => errorCode === `${code}`))
  }

  return errorEntry ? errorEntry[0] : `${ONEPLAYERUI_CONFIG.DEFAULT_NO_CODE_FOUND_ERROR}: ${code}`
}

/**
 *
 * @param error
 * @returns {{}}
 */
export function formatTokenError (error) {
  const isTimeOut = error.code === API_CONFIG.ERROR_TOKEN.ECONNABORTED
  const code = isTimeOut ? API_CONFIG.ERROR_TOKEN.TOKEN_TIMEOUT : error.message?.split(' ').pop() // last item in message is code and error.code is undefined

  return {
    name: error.name || '',
    message: error.message || '',
    origin: API_CONFIG.ORIGIN,
    errorCode: `${API_CONFIG.ERROR_TOKEN.PREFIX}${code}`
  }
}
