import { defineStore } from 'pinia'
import { useUserAgentStore } from '../useragent/userAgentStore'
import _get from 'lodash/get'
import { DateTime } from 'luxon'
import fingerprint from '@wptv/fingerprint'
import { useErrorStore } from '../errors/errorStore'

export const useUserStore = defineStore('user', {
  state: () => ({
    user: {},
    timeOffset: 0,
    wassupHeaders: {}
  }),
  actions: {
    async fetchUser () {
      const userAgent = useUserAgentStore()
      return await this.$nuxt.app.$api.tvm.users.getUser({ terminalModel: userAgent.getTerminalModel })
        .then((user) => {
          this.user = user
        })
        .catch((error) => {
          useErrorStore().setAxiosError(error)
          this.user = {}
        })
    },

    async fetchTimeOffset () {
      return await this.$nuxt.app.$api.internal.about.getAbout()
        .then((about) => {
          this.timeOffset = _parseTimeOffset(about.data.serverDate)
        })
        .catch((err) => {
          this.timeOffset = _parseTimeOffset(_get(err, 'error.date'))
        })
    },

    async postErrorLogs (error) {
      return await this.$nuxt.app.$api.tvm.users.postErrorLogs(error)
    }
  },
  getters: {
    uniqueId (state) {
      const userAgent = useUserAgentStore().userAgent
      const browser = fingerprint.browser()
      const os = fingerprint.os()
      const householdId = _get(state.user, 'householdId')
      const suffixFP = _get(userAgent, 'os.name').includes('Mac') &&
      _get(userAgent, 'ua').includes('Safari') &&
      _get(userAgent, 'ua').includes('Chrome')
        ? '_FP'
        : ''

      const browserName = browser.name

      const terminalId = householdId
        ? [
            os.name.replace(/[()]/g, ''),
            os.bits,
            browserName,
            householdId
          ].join('-')
        : ''

      return (terminalId + suffixFP).replace(' ', '')
    }
  }
})

function _parseTimeOffset (serverDate) {
  return serverDate ? DateTime.local().toMillis() - DateTime.fromHTTP(serverDate).setZone('Europe/Paris').toMillis() : 0
}
