import { defineStore } from 'pinia'
import UAParser from 'ua-parser-js'
import _get from 'lodash/get'

export const useUserAgentStore = defineStore('userAgent', {
  state: () => ({
    userAgent: {}
  }),
  actions: {
    parseUserAgent (userAgentString): void {
      this.userAgent = new UAParser(userAgentString).getResult()
    }
  },
  getters: {
    osName (state): string {
      return _get(state, 'userAgent.os.name')
    },

    deviceType (state): string {
      return _get(state, 'state.userAgent.device.type', 'pc')
    },

    browserName (state): string {
      return _get(state, 'userAgent.browser.name')
    },

    browserMajor (state): string {
      return _get(state, 'userAgent.browser.major')
    },

    osVersion (state): string {
      return _get(state, 'userAgent.os.version')
    },

    isMac () : boolean {
      return this.osName === 'Mac OS'
    },

    isWindows () : boolean {
      return this.osName === 'Windows'
    },

    isWindowsPhone () : boolean {
      return this.osName === 'Windows Phone'
    },

    isAndroid () : boolean {
      return this.osName === 'Android'
    },

    isIOS () : boolean {
      return this.osName === 'iOS'
    },

    isPC () : boolean {
      return this.isMac || this.isWindows
    },

    isCompatibleMobile () : boolean {
      return this.isAndroid || this.isIOS
    },

    isSafari (state): boolean {
      return _get(state, 'userAgent.browser.name', '').toLowerCase().includes('safari')
    },

    isIpad (state) {
      return _get(state, 'userAgent.device.model', false) === 'iPad'
    },

    isEdge (state): boolean {
      return _get(state, 'userAgent.browser.name', '').toLowerCase().includes('edge')
    },

    isChrome (state): boolean {
      return _get(state, 'userAgent.browser.name', '').toLowerCase().includes('chrome')
    },

    isFirefox (state): boolean {
      return _get(state, 'userAgent.browser.name', '').toLowerCase().includes('firefox')
    },

    getTerminalModel (): string {
      const osName = _get(this.userAgent, 'os.name', '')
      const ua = _get(this.userAgent, 'ua', '')
      // TerminalModel = WEB_SAFARI si on est sur mac + safari
      // Attention le useragent chrome mac contient safari, d'ou la derniere condition
      return osName && (osName.includes('Mac') || osName.includes('iOS')) && ua.includes('Safari') && !ua.includes('Chrome') ? 'WEB_SAFARI' : 'WEB_PC'
    }
  }
})
