export default {
  title: `Replay : {title}`,
  description: `Tous vos programmes TV :  films et séries de la chaine {title} en replay et streaming , sur tous vos écrans.`,
  watchAll: 'Tout voir',
  accessibility: {
    spotlight: 'Mise en avant',
    goToCategory: 'Accéder à la rubrique {target}',
    rubricTitle: 'Rubrique {title}',
    watchOne: `Plus d'informations sur {channel}, {title}, {pitch}`
  },
  returnLink: 'Revenir à la mosaïque',
  watchOne: 'Plus d\'informations'
}
