export default {
  404: {
    generic: {
      title: 'Page introuvable - REPLAY Orange',
      notFound: `Désolé, la page demandée n'est plus disponible`,
      unknown: `Une erreur est survenue`,
      or: 'ou',
      redirectMessage: `Vous allez être redirigé(e) vers la page d'accueil du replay dans {count} ...`,
      link: `Cliquez ici`
    },
    video: {
      redirectMessage: `Vous allez être redirigé(e) vers la page de l'émission dans {count} ...`
    }
  },
  modals: {
    confirm: 'Ok',
    retry: 'Réessayer',
    close: 'Fermer',
    REPLAY_DATA_NOT_FOUND: {
      title: `Information`,
      content: `Le contenu demandé n'est pas disponible`,
      theme: `info`
    },
    moreInfo: 'Plus d\'informations',
    genericErrorMessage: 'Nous sommes désolés pour la gêne occasionnée. Si le problème persiste veuillez contacter le support technique.',
    MAX_SIMULTANEOUS_VIEWS_REACHED: {
      title: `Accès limité`,
      content: `Vous avez atteint le nombre maximum de sessions simultanées autorisées sur la TV d'Orange. {link}`,
      link: {
        url: `http://r.orange.fr/r/Owebtv_replay_cptflux`,
        text: `En savoir plus`
      },
      theme: `warning`
    },
    MAX_TERMINALS_EXCEEDED: {
      title: `Accès limité`,
      content: `Vous avez atteint le nombre maximum de terminaux autorisés sur la TV d'Orange. {link}`,
      link: {
        url: `http://r.orange.fr/r/Owebtv_replay_cptflux`,
        text: `En savoir plus`
      },
      theme: `warning`
    },
    BROWSER_UNCOMPATIBLE: {
      title: `Information`,
      content: `Cette vidéo ne peut pas être visionnée avec ce navigateur web sur cet écran.<br/> Visionnez-la sur un autre navigateur ou sur un {link}.`,
      link: {
        url: `https://r.orange.fr/r/Owebtv_tvod_config`,
        text: `autre écran compatible`
      },
      theme: `info`
    },
    MOBILE_ERROR: {
      title: `Configuration non autorisée`,
      content: `Votre configuration ne vous permet pas d'accéder à ce service. Nous vous invitons à utiliser un autre navigateur et/ou autre système d'exploitation. Pour plus d'informations rendez vous sur {link}`,
      link: {
        url: `https://r.orange.fr/r/Owebtv_tvod_config`,
        text: `l'assistance en ligne.`
      },
      theme: `warning`
    },
    POPIN_NOT_HLS_MAC_ERROR: {
      title: `Information`,
      content: `Cette vidéo ne peut temporairement pas être visionnée avec Safari.<br/> Sur Mac, vous pouvez la visionner avec Chrome ou Firefox.`,
      theme: `warning`
    },
    DEFAULT_API_ERROR: {
      title: `Erreur technique ({statusCode} / {errorCode})`,
      content: `Une erreur est survenue : {apiDescription} <br/> Merci d'essayer à nouveau.<br/> Si le problème persiste, veuillez contacter le support via le lien "nous contacter" en bas de page.`,
      theme: `erreur`
    }
  }
}
