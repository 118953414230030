import statsUtils from './utils'

/**
 * On Scroll top
 * @param page
 * @returns {{track_page: string, track_zone: string, track_nom: string}}
 */
function onClickScrollTopButton (page) {
  return {
    track_page: statsUtils.getTrackPage(page),
    track_zone: 'page',
    track_nom: 'retour_haut'
  }
}

function onClickItem (trackpage, trackzone, itemId, trackdetail) {
  return {
    track_page: trackpage,
    track_zone: trackzone,
    track_nom: 'choix_emission',
    track_type: 'clic',
    track_cible: itemId,
    track_detail: trackdetail
  }
}

function onClickChannelMosaique (page, key) {
  return {
    track_page: page,
    track_zone: 'mosaique',
    track_nom: 'chaine',
    track_type: 'clic',
    track_cible: key,
    track_detail: 'clic_chaine_mosaique_' + page
  }
}

/**
 * When Out of Eu modal is open
 * @returns {{area: string, cible: string, zone: string, page: string, type: string}}
 */
function onTriggerOutOfEu () {
  return {
    page: 'all',
    zone: 'popin',
    area: 'blocage_ue',
    cible: '',
    type: 'affichage'
  }
}

function onClickOutOfEuHelp (supportUrl) {
  return {
    track_page: 'all',
    track_zone: 'popin',
    track_nom: 'blocage_ue',
    track_type: 'clic-assist',
    track_cible: supportUrl,
    track_detail: 'clic-assist-blocage_ue-popin-all'
  }
}

function onClickICloudPrivateRelayHelp (supportUrl) {
  return {
    track_page: 'all',
    track_zone: 'popin',
    track_nom: 'blocage_ue',
    track_type: 'clic-relais_prive',
    track_cible: supportUrl,
    track_detail: 'clic-relais_prive-blocage_ue-popin-all'
  }
}

function onTriggerNotAuthenticated () {
  return {
    page: 'toutes_pages',
    zone: 'non_authentifie',
    area: 'popin',
    cible: '',
    type: 'affichage'
  }
}

function onTriggerNotSubscribed () {
  return {
    page: 'toutes_pages',
    zone: 'bouquet_not_subscribed',
    area: 'popin',
    cible: '',
    type: 'affichage'
  }
}

function onTriggernoNadismNotAllowed () {
  return {
    page: 'toutes_pages',
    zone: 'nomadism_not_allowed',
    area: 'popin',
    cible: '',
    type: 'affichage'
  }
}

// ---------------------------------------

export default {
  onClickScrollTopButton,
  onClickItem,
  onClickChannelMosaique,
  onTriggerOutOfEu,
  onTriggerNotSubscribed,
  onClickOutOfEuHelp,
  onClickICloudPrivateRelayHelp,
  onTriggerNotAuthenticated,
  onTriggernoNadismNotAllowed
}
