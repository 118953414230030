import user from './user/user'
import CONSTANTS from '../../components/shared/api.config'
import { useConfigStore } from '../../store/config/configStore'

/**
 * Main test to make auth requests
 * @param profile
 * @returns {boolean|boolean|*|(function(*): *)|(function(*): *)}
 */
export function profileHasAccess (profile) {
  return (
    profile.isAuthenticated &&
    profile.suspended === CONSTANTS.SUSPENDED_CODES.NA &&
    profile.isUe
  )
}

export function accessChecks ($pinia, store, profile, firstRender, requiresAuth) {
  return user.isUe(profile)
    .then(() => user.isNotSuspended(profile))
    .then(() => user.hasErrorOnTokenTvInit(store))
    .then(() => user.isAuthenticated(requiresAuth, profile))
    .catch(({ componentName, props }) => {
      useConfigStore($pinia).addModalToOpen({ componentName, props })
      return Promise.reject()
    })
}
