function onClickWatch (videoId) {
  return {
    track_page: 'FIP',
    track_zone: 'player',
    track_nom: 'regarder_emission',
    track_type: 'clic',
    track_cible: videoId,
    track_detail: 'clic-regarder_emission-player-FIP'
  }
}

export default {
  onClickWatch
}
