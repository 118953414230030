export default {
  back: 'Retour',
  description: `Voir en replay et en streaming {title} - Orange`,
  summarySectionTitle: `Plus d'informations`,
  replayButton: 'Regarder le Replay',
  liveSectionTitle: 'Direct',
  liveInfo: `Retrouvez tous les programmes en direct de la chaîne {channelName}`,
  metaInfo: {
    today: `diffusé aujourd'hui à {strTime}`,
    yesterday: `diffusé hier à {strTime}`,
    date: `diffusé le {strDate} à {strTime}`,
    lastDay: 'dernier jour',
    stillAvailable: `disponible encore {daysLeft} jours`
  },
  buttons: {
    watch: 'Regarder',
    watchAria: `Regarder {title}`
  },
  liveSection: {
    watchButton: `Voir la chaîne en Direct`,
    watchButtonAria: `Voir la chaîne {channel} en direct`
  }
}
