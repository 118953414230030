function onClickChannelHome (channelId) {
  return {
    track_page: 'home',
    track_zone: 'mosaique',
    track_nom: 'choix_chaine',
    track_type: 'clic',
    track_cible: channelId,
    track_detail: 'clic-choix_chaine-mosaique-home'
  }
}

function onClickExternalChannel (channelId) {
  return {
    track_page: 'home',
    track_zone: 'mosaique',
    track_nom: 'item',
    track_type: 'clic',
    track_cible: channelId
  }
}

export default {
  onClickChannelHome,
  onClickExternalChannel
}
