import { defineStore } from 'pinia'
import { useUserStore } from '../user/userStore'
import _last from 'lodash/last'
import {
  formatAxiosError,
  formatPlayerError,
  formatGenericError
} from './format/format'

export const useErrorStore = defineStore('error', {
  state: () => ({
    errors: [],
    nbErrorsInSession: 0
  }),
  actions: {
    setPlayerError (error: any, bypass: boolean = false): void {
      this.setNbErrorsInSession()
      const playerError = formatPlayerError(error, {
        portalVersion: this.$nuxt.$config.appVersion,
        nbInSession: this.nbInSession
      })

      useUserStore().postErrorLogs(playerError)

      if (!bypass) {
        this.errors.push(playerError)
      }
    },

    setAxiosError (error: any, bypass: boolean = false): Promise<any> {
      this.setNbErrorsInSession()
      const axiosError = formatAxiosError(error, {
        portalVersion: this.$nuxt.$config.appVersion,
        nbInSession: this.nbInSession
      })

      useUserStore().postErrorLogs(axiosError)

      if (!bypass) {
        this.errors.push(axiosError)
        return Promise.reject(error)
      }

      return Promise.resolve(error)
    },

    setGenericError (error: any, bypass: boolean = false): Promise<any> {
      this.setNbErrorsInSession()
      const genericError = formatGenericError(error, {
        nbInSession: this.nbInSession,
        portalVersion: this.$nuxt.$config.appVersion
      })

      useUserStore().postErrorLogs(genericError)

      if (!bypass) {
        this.errors.push(genericError)
        return Promise.reject(error)
      }

      return Promise.resolve(error)
    },

    setNbErrorsInSession (): void {
      this.nbErrorsInSession++
    }
  },
  getters: {
    getLastApiError: state => _last(state.errors.filter(error => error.type === 'API'))
  }
})
