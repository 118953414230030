import urlJoin from 'url-join'
import _get from 'lodash/get'
import axios from 'axios'
import logger from '@wptv/logger'

export default {
  /**
   * Get user
   * @param terminalModel
   * @returns {Promise<* | never>}
   */
  getUser ({ terminalModel }) {
    const method = 'api.users.getUser()'
    const options = { params: { terminalModel } }
    const path = urlJoin(
      'applications',
      this.getApplication())

    return this.$http.catchup.auth.get(path, options)
      .then(response => this.$httpUtils.parseResponse(response, false))
      .catch(responseError => this.$httpUtils.catchError({ responseError, method }))
  },
  /**
   * Get server clock
   * TODO use luxon and set in store instead
   * @returns {Promise<AxiosResponse<any> | number>}
   */
  getServerClock () {
    const path = urlJoin(
      'applications',
      this.getApplication(),
      _get(this.$config, 'confetti.public.urls.rpBaseUrl'),
      'about'
    )
    return axios.get(path)
      .then(res => new Date(res.headers.date).valueOf())
      .catch(() => Date.now())
  },
  /**
   * Post the error logs encountered by the user
   * Note: we don't catch error with http-utils because we don't want to log neither show it
   * @returns {Promise<T | never>}
   */
  postErrorLogs (error) {
    const path = urlJoin(
      _get(this.$config, 'confetti.public.api.path.error'))

    return this.$http.tvm.authErrorLogs.post(path, error)
      .catch((responseError) => {
        // we don't catch error with http-utils because we don't want to log neither show it
        logger.error('Error::api.user.postErrorLogs():', responseError.message + ':' + path)
        logger.debug(responseError)
        return Promise.reject()
      })
  }

}
