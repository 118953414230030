export default {
  scrollTop: 'Remonter en haut de page',
  replayItem: {
    access: {
      goToFip: 'Accéder à l\'émission {target}'
    }
  },
  MORE: 'En savoir plus',
  CONFIRM: 'ok',
  PROGRAM: 'émission | émissions',
  SERIE: 'épisode | épisodes',
  participantsList: {
    dirHeader: 'Réalisé par : ',
    actHeader: 'Avec : ',
    mesHeader: 'Mis en scène par : ',
    autHeader: 'De : ',
    preHeader: 'Présenté par : ',
    proHeader: 'Produit par : ',
    choHeader: 'Chorégraphie de : '
  },
  menu: {
    default_menu_lateral: 'Plus',
    replay: 'Replay',
    home: 'Toutes les chaînes',
    a11y: {
      menu_lateral: 'menu {label}, {nb} entrées'
    }
  },
  mosaique: {
    TITLE_NB_VIDEOS: '{nb} programme | {nb} programmes',
    empty: 'Aucun contenu disponible.'
  },
  backTo: 'Retour vers {target}'
}
