import urlJoin from 'url-join'
import _get from 'lodash/get'

export default {
  /**
   * Get a specific video by id
   * @param videoId
   * @returns {Promise<* | never>}
   */
  getVideo (videoId) {
    const method = 'api.videos.getVideo()'
    const path = urlJoin(
      'applications',
      this.getApplication(),
      _get(this.$config, 'confetti.public.api.path.videos'),
      videoId)
    const options = {
      cache: {
        exclude: { query: false }
      }
    }
    return this.$http.catchup.notAuth.get(path, options)
      .then(response => this.$httpUtils.parseResponse(response, false))
      .catch(responseError => this.$httpUtils.catchError({ responseError, method }))
  },
  /**
   * Get playing informations
   * @param videoId
   * @param terminalId
   * @param terminalModel
   * @returns {Promise<* | never>}
   */
  getPlayingInfo ({ videoId, terminalId, terminalModel }) {
    const method = 'api.videos.getPlayingInfo()'
    const path = urlJoin(
      'applications',
      this.getApplication(),
      _get(this.$config, 'confetti.public.api.path.stream'),
      videoId,
      'stream'
    )
    const options = {
      params: {
        terminalModel,
        terminalId
      }
    }

    return this.$http.catchup.auth.get(path, options)
      .then(response => this.$httpUtils.parseResponse(response, false))
      .catch(responseError => this.$httpUtils.catchError({ responseError, method }))
  }

}
