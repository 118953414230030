function onClickCarouselRubrique (page, rubriqueId, rubriqueName) {
  return {
    track_page: page,
    track_zone: 'carousel_rubrique',
    track_nom: 'titre_rubrique',
    track_type: 'clic',
    track_cible: rubriqueId + ' - ' + rubriqueName,
    track_detail: 'clic-titre_rubrique-carrousel_rubrique-corner'
  }
}

function onClickCarouselShowAll (page, rubriqueId, rubriqueName) {
  return {
    track_page: page,
    track_zone: 'carrousel_rubrique',
    track_nom: 'tout_afficher',
    track_type: 'clic',
    track_cible: rubriqueId + ' - ' + rubriqueName,
    track_detail: 'clic-tout_afficher-carrousel_rubrique-corner'
  }
}

export default {
  onClickCarouselRubrique,
  onClickCarouselShowAll
}
