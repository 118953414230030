export default {
  channelLogoActionLabel: 'Accéder à la chaîne {channel}',
  kind: 'Genre',
  productionDate: 'Produit en',
  productionCountry: 'Pays d\'origine',
  csa: 'Déconseillé aux moins de {age} ans',
  hd: 'HD',
  hdAvailable: 'Disponible en HD',
  videoLanguageVersion: 'Langue de la vidéo {langue}',
  audioDescription: 'Audio description disponible',
  impairedHearing: 'Sous titrage pour malentendant disponible',
  notImpairedHearing: 'Sous titrage pour malentendant non disponible',
  playVideo: 'Regarder {video}'
}
