import _get from 'lodash/get'
import { accessChecks } from './checkups'
import { useConfigStore } from '../store/config/configStore'

export default ({ route, from, store, redirect, $pinia }) => {
  // Clear modals if any still being open before switching to the new page
  useConfigStore($pinia).clearModalsToOpen()

  if (route.path === '/channels') {
    redirect(301, '/')
  }

  // Get data for auth guards
  const firstRender = process.server
  const profile = store.getters['core/getProfile']
  const requiresAuth = !!_get(route, 'meta[0].requiresAuth', false)

  // On first render (ssr) always run checks, with auth check if route requires it
  // Then for client side, run check only if route requires auth
  if (firstRender || requiresAuth) {
    return accessChecks($pinia, store, profile, firstRender, requiresAuth)
      .catch(() => {
        if (requiresAuth) {
          redirect(from || '/')
        }
      })
  }
}
