<template>
  <div id="app">
    <main class="box">
      <header-layout />
      <Menu class="menu" />
      <Nuxt />
      <wptv-scroll-top-button
        :title="$t('general.scrollTop')"
        :tealium="$stats.global.onClickScrollTopButton()"
        data-wptv="retour_haut" />
    </main>

    <footer>
      <wptv-footer :used-o-s="userAgentStore.osName" />
    </footer>

    <component
      :is="activeModal"
      v-if="configStore.isShowModal"
      ref="activeModal"
      v-bind="activeModalProps"
      @closed="closeModal" />
  </div>
</template>

<script>
import { WptvScrollTopButton } from '@wptv/components/ui/scrollTopButton'
import commonElements from '../components/shared/commonElements/commonElements.mixin'
import HeaderLayout from '../components/shared/header/Header.vue'
import modals from '../components/shared/modals/modals.mixin'
import Menu from '../components/shared/menu/Menu.vue'
import { WptvFooter } from '@wptv/components/ui/footer'
import { mapStores } from 'pinia'
import { useUserAgentStore } from '../store/useragent/userAgentStore'

export default {
  components: {
    WptvScrollTopButton,
    HeaderLayout,
    Menu,
    WptvFooter
  },
  mixins: [
    commonElements,
    modals
  ],
  computed: {
    ...mapStores(useUserAgentStore)
  },
  mounted () {
    this.$root.$on('page-loaded', () => {
      this.onLoaded()
    })
  },
  methods: {
    onLoaded () {
      this.$navik.restorePageState(this.$store)
    }
  }

}
</script>
<style lang="scss" scoped>
.menu {
  position: sticky;
  top: 0;
  max-width: 90rem;
  @include mediaQueries('padding', (xsmall: 0 0.9375rem 0, medium: 0 0.75rem 0, large: 0 2rem 0, xlarge: 0 2.5rem 0));
  margin: auto;
  background-color: $white;
  z-index: 98;
}
</style>
