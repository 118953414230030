import API from '../api.config'
import { mapStores } from 'pinia'
import { useConfigStore } from '../../../store/config/configStore'
import { useErrorStore } from '../../../store/errors/errorStore'

const Authentication = () => import('./authentication/Authentication')
const OutOfEu = () => import('./outOfEu/OutOfEu')
const Suspended = () => import('./suspended/Suspended')
const ErrorModal = () => import('./errorModal/ErrorModal')
const ErrorTvInitModal = () => import('./errorTvInitModal/ErrorTvInitModal')
const NotSubscribed = () => import('./notSubscribed/NotSubscribed')
const NomadismNotAllowed = () => import('./nomadismNotAllowed/NomadismNotAllowed')

export default {
  computed: {
    ...mapStores(useConfigStore, useErrorStore),
    firstModalToOpen () {
      return this.configStore.getFirstModalToOpen
    },
    lastApiError () {
      return this.errorStore.getLastApiError
    }
  },
  data () {
    return {
      activeModal: null,
      activeModalProps: null,
      lastApiErrorWatcher: null,
      firstModalToOpenWatcher: null
    }
  },
  components: {
    Authentication,
    OutOfEu,
    Suspended,
    NotSubscribed,
    NomadismNotAllowed,
    ErrorModal,
    ErrorTvInitModal
  },
  mounted () {
    this.$root.$on('open-authentication-modal', () => this.openModal('Authentication'))
    this.$root.$on('open-out-of-eu-modal', () => this.openModal('OutOfEu'))
    this.$root.$on('open-error-token-modal', () => this.openModal('ErrorTvInitModal'))
    this.$root.$on('open-suspended-modal', props => this.openModal('Suspended', props))
    this.$root.$on('open-bouquet-not-subscribed-modal', props => this.openModal('NotSubscribed', props))
    this.$root.$on('open-nomadism-not-allowed-modal', () => this.openModal('NomadismNotAllowed'))

    this.lastApiErrorWatcher = this.$store.watch(() => this.lastApiError, this.onError)

    if (this.firstModalToOpen) {
      this.onFirstModalToOpen()
    }

    this.firstModalToOpenWatcher = this.$store.watch(() => this.firstModalToOpen, this.onFirstModalToOpen)
  },
  beforeDestroy () {
    this.$root.$off()

    this.lastApiErrorWatcher()
    this.firstModalToOpenWatcher()
  },
  methods: {
    /**
     * Open modal - load dynamically
     * @param componentName
     * @param props
     */
    openModal (componentName, props = null) {
      this.configStore.addModalToOpen({ props, componentName })
    },

    /**
     * Closing modal (will set v-if to false)
     */
    closeModal () {
      this.configStore.closeModal()
    },

    /**
     * Called when first modal to open changes and open modal if any
     */
    onFirstModalToOpen () {
      if (!!this.firstModalToOpen && !this.configStore.isShowModal) {
        this.activeModalProps = this.firstModalToOpen.props
        this.activeModal = this.firstModalToOpen.componentName

        this.configStore.showModal()
      }
    },
    /**
     * Open modal when error occured / throw error page if 404
     */
    onError () {
      const errorCode = Object.keys(API.ERRORS_CODES).find(code => API.ERRORS_CODES[code].includes(this.lastApiError.errorCode)) || 'DEFAULT_API_ERROR'
      switch (errorCode) {
        case 'TOKEN_EXPIRED':
        case 'REPLAY_DATA_NOT_FOUND':
          // 5802: we don't want to show modal on expired token
          // FDE: we don't want to show modal on 404 since we already show the 404 page
          break
        case 'NOT_AUTHENTICATED':
          // 5737: we still want to manage api 401 errors
          // like when cookie expires for ex, we can still get 401 from apis
          this.openModal('Authentication')
          break
        default:
          this.openModal('ErrorModal', {
            code: errorCode,
            error: this.lastApiError
          })
          break
      }
    }
  }
}
